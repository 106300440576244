import { AppDatabase } from 'services/appDatabase';
import Engine from 'services/engine/engine';
import PriceAdjustedOccupancyEngine from 'services/engine/priceAdjustedOccupancy';
import RevenueEngine from 'services/engine/revenue';
import CottagesAveragePriceChange from './averagePriceChange';
import CottagesBenchmarkEngine from './benchmark';
import CottagesOccupancyEngine from './occupancy';
import CottagesPricingEngine from './pricing';
import CottagesShortBreakEngine from './shortBreak';

export default (database: AppDatabase) =>
  new Engine(
    database,
    CottagesBenchmarkEngine,
    CottagesOccupancyEngine,
    CottagesPricingEngine,
    RevenueEngine,
    CottagesShortBreakEngine,
    CottagesAveragePriceChange,
    PriceAdjustedOccupancyEngine,
  );
