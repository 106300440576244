import React from 'react';
import { connect } from 'react-redux';

import withTaxRate from 'hoc/withTaxRate';
import { getEngineCurrency, getUserCurrency } from 'stateManagement/Currency/selectors';
import { getQuoteCommissionRate, getSetNetIncome } from 'stateManagement/Quote/selectors';
import { RootState } from 'stateManagement/types';
import { ICurrency } from 'services/appDatabase';
import { guestToOwnerPrice, ownerToGuestPrice } from 'services/helpers/guestOwnerPriceConverters';

const engineOutputPriceType: 'net' | 'gross' = window.config.site === 'cottages' ? 'gross' : 'net';

type Nullable<T> = T | null;
interface IBaseCurrencyInfo {
  userCurrency: Nullable<ICurrency>;
  engineCurrency: Nullable<ICurrency>;
  taxRate: number;
  commissionRate: number;
  useNetIncome: boolean;
}

type IProps = IBaseCurrencyInfo;
export interface ICurrencyContext extends IBaseCurrencyInfo {
  convertToDefaultCurrency: (userCurrencyValue: number) => number;
  convertToUserCurrency: (defaultCurrencyValue: number) => number;
  toGross: (amount: number) => number;
  fromGross: (amount: number) => number;
  toNet: (amount: number) => number;
  fromNet: (amount: number) => number;
  toToggleType: (amount: number) => number;
  toEngineType: (amount: number) => number;
}

const CurrencyContext = React.createContext<ICurrencyContext>({
  convertToDefaultCurrency: (userCurrencyValue: number) => userCurrencyValue,
  convertToUserCurrency: (defaultCurrencyValue: number) => defaultCurrencyValue,
  toGross: (amount: number) => amount,
  fromGross: (amount: number) => amount,
  toNet: (amount: number) => amount,
  fromNet: (amount: number) => amount,
  toToggleType: (amount: number) => amount,
  toEngineType: (amount: number) => amount,
  userCurrency: null,
  engineCurrency: null,
  taxRate: 0,
  commissionRate: 0,
  useNetIncome: false,
});
class CurrencyProvider extends React.PureComponent<IProps> {
  render() {
    const { userCurrency, engineCurrency, useNetIncome } = this.props;
    const togglePriceType = useNetIncome ? 'net' : 'gross';

    const genericCurrencyConvert =
      (fromCurrency: Nullable<ICurrency>, toCurrency: Nullable<ICurrency>) =>
      (amountToConvert: number): number => {
        if (fromCurrency && toCurrency) {
          return (
            (toCurrency.conversionRateToDefault / fromCurrency.conversionRateToDefault) *
            amountToConvert
          );
        }
        return amountToConvert;
      };
    const convertToDefaultCurrency = genericCurrencyConvert(userCurrency, engineCurrency);
    const convertToUserCurrency = genericCurrencyConvert(engineCurrency, userCurrency);

    // engine -> gross
    const toGross = (amount: number): number => {
      switch (engineOutputPriceType) {
        case 'gross':
          return amount;
        case 'net':
          return ownerToGuestPrice(amount, this.props.commissionRate, this.props.taxRate);
      }
    };
    // gross -> engine
    const fromGross = (amount: number): number => {
      switch (engineOutputPriceType) {
        case 'gross':
          return amount;
        case 'net':
          return guestToOwnerPrice(amount, this.props.commissionRate, this.props.taxRate);
      }
    };
    // engine -> net
    const toNet = (amount: number): number => {
      switch (engineOutputPriceType) {
        case 'gross':
          return guestToOwnerPrice(amount, this.props.commissionRate, this.props.taxRate);
        case 'net':
          return amount;
      }
    };
    // net -> engine
    const fromNet = (amount: number): number => {
      switch (engineOutputPriceType) {
        case 'gross':
          return ownerToGuestPrice(amount, this.props.commissionRate, this.props.taxRate);
        case 'net':
          return amount;
      }
    };
    // engine -> toggle
    const toToggleType = (amount: number): number => {
      switch (togglePriceType) {
        case 'gross':
          return toGross(amount);
        case 'net':
          return toNet(amount);
      }
    };
    // toggle -> engine
    const toEngineType = (togglePriceTypeAmount: number): number => {
      if (engineOutputPriceType === togglePriceType) {
        return togglePriceTypeAmount;
      }

      switch (engineOutputPriceType) {
        case 'gross':
          return ownerToGuestPrice(
            togglePriceTypeAmount,
            this.props.commissionRate,
            this.props.taxRate,
          );
        case 'net':
          return guestToOwnerPrice(
            togglePriceTypeAmount,
            this.props.commissionRate,
            this.props.taxRate,
          );
      }
    };

    return (
      <CurrencyContext.Provider
        value={{
          ...this.props,
          convertToDefaultCurrency,
          convertToUserCurrency,
          toGross,
          fromGross,
          toNet,
          fromNet,
          toToggleType,
          toEngineType,
        }}
      >
        {this.props.children}
      </CurrencyContext.Provider>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  userCurrency: getUserCurrency(state),
  engineCurrency: getEngineCurrency(state),
  commissionRate: getQuoteCommissionRate(state),
  useNetIncome: getSetNetIncome(state),
});

export const ConnectedCurrencyProvider = withTaxRate(connect(mapStateToProps)(CurrencyProvider));

export const CurrencyConsumer = CurrencyContext.Consumer;
export default CurrencyContext;
