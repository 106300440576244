import { INewProperty } from 'services/appDatabase';
import PricingEngine from 'services/engine/pricing';
import { IPricingEngine } from 'services/engine/types';
import { getBigPropertyRegion } from './utils';

class CottagesPricingEngine extends PricingEngine implements IPricingEngine {
  protected async getPricingRegionCode(property: INewProperty): Promise<string> {
    const bigPropertyRegion = getBigPropertyRegion(property);
    return bigPropertyRegion || super.getPricingRegionCode(property);
  }
}

export default CottagesPricingEngine;
