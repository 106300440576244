import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DateTime, Duration } from 'luxon';
import React from 'react';

interface IProps {
  estimatedDuration?: Duration;
  open: boolean;
  title: string;
}

interface IState {
  startTime: DateTime;
  progress: number;
  prevOpen: boolean;
}

// This modal is not closable
const onClose = () => null;
const makeUserFriendly = (x: number) => 1 - Math.exp(-3 * x);

class LoadingModal extends React.PureComponent<IProps, IState> {
  static getDerivedStateFromProps(props: IProps, state: IState) {
    const stateUpdate: Partial<IState> = {
      prevOpen: props.open,
    };

    if (props.open && !state.prevOpen) {
      stateUpdate.progress = 0;
      stateUpdate.startTime = DateTime.local();
    }

    return stateUpdate;
  }

  timerID: number = 0;
  state = {
    progress: 0,
    startTime: DateTime.invalid('default'),
    prevOpen: false,
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.update(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  update() {
    const { estimatedDuration } = this.props;
    this.setState(({ startTime }) => {
      const now = DateTime.local();
      const currentDuration = now.diff(startTime);
      const linearProgress = estimatedDuration
        ? currentDuration.as('seconds') / estimatedDuration.as('seconds')
        : 0;
      const userFriendlyProgress = makeUserFriendly(linearProgress);
      return {
        progress: userFriendlyProgress * 100,
      };
    });
  }

  render() {
    const { open, title, estimatedDuration } = this.props;
    const { progress } = this.state;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby="loading-modal-title"
        open={open}
        maxWidth="xs"
        fullWidth
        data-test="cy-loading-modal"
      >
        <DialogTitle disableTypography id="loading-modal-title">
          <Typography variant="h3">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <LinearProgress
            variant={estimatedDuration ? 'determinate' : undefined}
            value={progress}
          />
          <br />
        </DialogContent>
      </Dialog>
    );
  }
}

export default LoadingModal;
