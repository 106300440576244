import appDb from '../appDatabase';
import makeCottagesEngine from './cottages';
import makeNovasolEngine from './novasol';

const constructorMap = {
  cottages: makeCottagesEngine,
  novasol: makeNovasolEngine,
};

const engine = constructorMap[window.config.site](appDb);

export default engine;
