import chunk from 'lodash/chunk';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setEngineCurrency } from 'stateManagement/Currency';
import appDB, {
  IBookingPerMonth,
  IBookingProbability,
  IBookingWindowPenalty,
  ICalendar,
  ICountry,
  ICurrency,
  IMarkup,
  IOccupancyCoefficient,
  IPriceCoefficient,
  IPricingRegion,
  IShortBreakArrivalDistribution,
} from 'services/appDatabase';
import { sameCountry, sameDataVersionNumber, updateDataVersionNumber } from 'services/dataVersion';
import client from 'services/networking/request';
import { isAfterSoftPageReload } from 'services/site/softPageReloader';
import { storeProperties, storeSectors } from 'services/storeData';
import { ActionType, getType } from 'typesafe-actions';
import { upstreamData } from './actions';

const propertiesEndpoint = '/api/upstream/properties/';
const sectorsEndpoint = '/api/upstream/sectors/';
const occupancyCoefficientsEndpoint = '/api/upstream/occupancy_coefficients/';
const featuresEndpoint = '/api/upstream/features/';
const bookingPerMonthEndpoint = '/api/upstream/booking_per_month/';
const bookingWindowPenaltyEndpoint = '/api/upstream/booking_window_penalties/';
const weekMappingEndpoint = '/api/upstream/week_mappings/';
const seasonEndpoint = '/api/upstream/seasons/';
const priceCoefficientEndpoint = '/api/upstream/price_coefficients/';
const bookingProbabilityEndpoint = '/api/upstream/booking_probabilities/';
const pricingRegionEndpoint = '/api/upstream/pricing_regions/';
const shortBreaksEndpoint = '/api/upstream/shortbreak_data/';
const calendarsEndpoint = '/api/upstream/calendars/';
const propertyTypesEndpoint = '/api/upstream/property_types/';
const countriesEndpoint = '/api/upstream/countries/';
const countryRegionsEndpoint = '/api/upstream/country_regions/';
const markupEndpoint = '/api/upstream/markups/';
const cascadeTypesEndpoint = '/api/upstream/cascade_types/';
const cascadeDataEndpoint = '/api/upstream/cascade_data/';
const currenciesDataEndpoint = '/api/upstream/currencies/';
const dataVersionNumberEndpoint = '/api/upstream/version/';
const weekDatesEndpoint = '/api/upstream/week-dates/';
const inflationEndpoint = '/api/upstream/inflation/';

export function* updateUpstreamDataSaga(action: ActionType<typeof upstreamData.request>): any {
  if (!navigator.onLine) {
    console.debug('%c🔌 Offline:' + 'not updating upstream data.', 'font-weight: bold;');
    yield put(upstreamData.success({}));
    return;
  }

  if (isAfterSoftPageReload()) {
    yield put(upstreamData.success({}));
    return;
  }

  try {
    const [dataVersionNumber, countries, currencies] = yield all([
      yield call([client, client.get], dataVersionNumberEndpoint),
      yield call([client, client.get], countriesEndpoint),
      yield call([client, client.get], currenciesDataEndpoint),
    ]);

    const cleanedCountries = cleanCountries(countries);
    const cleanedCurrencies = cleanCurrency(currencies);

    if (sameDataVersionNumber(dataVersionNumber)) {
      const isSameCountry = yield call(sameCountry, countries);
      if (isSameCountry) {
        yield put(upstreamData.success({}));
        return;
      }
    }

    let properties = yield call([client, client.get], propertiesEndpoint, true);
    yield call([appDB.upstreamProperties, appDB.upstreamProperties.clear]);
    properties = chunk(properties, 3000);
    for (const chunkProperties of properties) {
      yield call(storeProperties, chunkProperties);
    }
    properties = null; // Clear memory, we have RAM issues on iPad

    let sectors = yield call([client, client.get], sectorsEndpoint);
    yield call([appDB.sectors, appDB.sectors.clear]);
    sectors = chunk(sectors, 3000);
    for (const chunkSectors of sectors) {
      yield call(storeSectors, chunkSectors);
    }
    sectors = null; // Clear memory, we have RAM issues on iPad

    const [
      occupancyCoefficients,
      features,
      bookingPerMonth,
      bookingWindowPenalties,
      weekMapping,
      season,
      priceCoefficient,
      bookingProbabilities,
      pricingRegion,
      shortBreaks,
      calendars,
      countryRegions,
      markup,
      cascadeTypes,
      cascadeData,
      propertyTypes,
      weekDates,
      inflation,
    ] = yield all([
      call([client, client.get], occupancyCoefficientsEndpoint),
      call([client, client.get], featuresEndpoint),
      call([client, client.get], bookingPerMonthEndpoint),
      call([client, client.get], bookingWindowPenaltyEndpoint),
      call([client, client.get], weekMappingEndpoint),
      call([client, client.get], seasonEndpoint),
      call([client, client.get], priceCoefficientEndpoint),
      call([client, client.get], bookingProbabilityEndpoint),
      call([client, client.get], pricingRegionEndpoint),
      call([client, client.get], shortBreaksEndpoint),
      call([client, client.get], calendarsEndpoint),
      call([client, client.get], countryRegionsEndpoint),
      call([client, client.get], markupEndpoint),
      call([client, client.get], cascadeTypesEndpoint),
      call([client, client.get], cascadeDataEndpoint),
      call([client, client.get], propertyTypesEndpoint),
      call([client, client.get], weekDatesEndpoint),
      call([client, client.get], inflationEndpoint),
    ]);

    yield call([appDB, appDB.clearDBExcept], ['newProperties', 'upstreamProperties', 'sectors']);

    const cleanedOccupancyCoefficients = cleanOccupancyCoefficients(occupancyCoefficients);
    const cleanedBookingWindowPenalties = cleanBookingWindowPenalties(bookingWindowPenalties);
    const cleanedBookingPerMonth = cleanBookingPerMonth(bookingPerMonth);
    const cleanedBookingProbabilities = cleanBookingProbability(bookingProbabilities);
    const cleanedPriceCoeffficients = cleanPriceCoeffficients(priceCoefficient);
    const cleanedPricingRegions = cleanPricingRegions(pricingRegion);
    const cleanedShortBreaks = cleanShortBreaks(shortBreaks);
    const cleanedCalendars = cleanCalendars(calendars);
    const cleanedMarkup = cleanMarkup(markup);

    yield all([
      call(
        [appDB.occupancyCoefficients, appDB.occupancyCoefficients.bulkAdd],
        cleanedOccupancyCoefficients,
      ),
      call([appDB.features, appDB.features.bulkAdd], features),
      call([appDB.bookingPerMonth, appDB.bookingPerMonth.bulkAdd], cleanedBookingPerMonth),
      call(
        [appDB.bookingWindowPenalty, appDB.bookingWindowPenalty.bulkAdd],
        cleanedBookingWindowPenalties,
      ),
      call([appDB.weekMapping, appDB.weekMapping.bulkAdd], weekMapping),
      call([appDB.season, appDB.season.bulkAdd], season),
      call([appDB.priceCoefficient, appDB.priceCoefficient.bulkAdd], cleanedPriceCoeffficients),
      call(
        [appDB.bookingProbability, appDB.bookingProbability.bulkAdd],
        cleanedBookingProbabilities,
      ),
      call([appDB.pricingRegion, appDB.pricingRegion.bulkAdd], cleanedPricingRegions),
      call([appDB.shortBreak, appDB.shortBreak.bulkAdd], cleanedShortBreaks),
      call([appDB.propertyTypes, appDB.propertyTypes.bulkAdd], propertyTypes),
      call([appDB.calendar, appDB.calendar.bulkAdd], cleanedCalendars),
      call([appDB.countries, appDB.countries.bulkAdd], cleanedCountries),
      call([appDB.countryRegions, appDB.countryRegions.bulkAdd], countryRegions),
      call([appDB.markup, appDB.markup.bulkAdd], cleanedMarkup),
      call([appDB.cascadeTypes, appDB.cascadeTypes.bulkAdd], cascadeTypes),
      call([appDB.cascadeData, appDB.cascadeData.bulkAdd], cascadeData),
      call([appDB.currencies, appDB.currencies.bulkAdd], cleanedCurrencies),
      call([appDB.weekDates, appDB.weekDates.bulkAdd], weekDates),
      call([appDB.inflation, appDB.inflation.bulkAdd], inflation),
    ]);

    yield put(upstreamData.success({}));

    updateDataVersionNumber(dataVersionNumber);
  } catch (error) {
    yield put(upstreamData.failure({ errorMessage: error.message }));
  }
}

function* storeDefaultsSaga() {
  const currencies: ICurrency[] = yield call(
    // @ts-ignore-next-line
    [appDB.currencies, appDB.currencies.toArray],
  );
  const siteCurrency = currencies.find(({ isDefaultForSite }) => isDefaultForSite);

  if (!siteCurrency) {
    throw new Error('Could not find site currency.');
  }

  yield put(setEngineCurrency(siteCurrency));
}

export default function* upstreamDataSagas() {
  yield takeEvery(getType(upstreamData.request), updateUpstreamDataSaga);
  yield takeEvery(
    [getType(upstreamData.success), getType(upstreamData.failure)],
    storeDefaultsSaga,
  );
}

const cleanOccupancyCoefficients = (occupancyCoefficients: any): IOccupancyCoefficient[] =>
  occupancyCoefficients.map((occupancyCoefficient: any) => ({
    ...occupancyCoefficient,
    value: parseFloat(occupancyCoefficient.coefficient),
  }));

const cleanBookingWindowPenalties = (bookingWindowPenalties: any): IBookingWindowPenalty[] =>
  bookingWindowPenalties.map((bookingWindowPenalty: any) => ({
    ...bookingWindowPenalty,
    penalty: parseFloat(bookingWindowPenalty.penalty),
  }));

const cleanBookingProbability = (bookingProbabilities: any): IBookingProbability[] =>
  bookingProbabilities.map((bookingProbability: any) => ({
    ...bookingProbability,
    bookingProbability: parseFloat(bookingProbability.bookingProbability),
  }));

const cleanBookingPerMonth = (bookingPerMonths: any): IBookingPerMonth[] =>
  bookingPerMonths.map((bookingPerMonth: any) => ({
    ...bookingPerMonth,
    percentageOfNights: parseFloat(bookingPerMonth.percentageOfNights),
  }));

const cleanPriceCoeffficients = (priceCoefficients: any): IPriceCoefficient[] =>
  priceCoefficients.map((priceCoefficient: any) => ({
    ...priceCoefficient,
    coefficient: parseFloat(priceCoefficient.coefficient),
  }));

const cleanPricingRegions = (pricingRegions: any): IPricingRegion[] =>
  pricingRegions.map((pricingRegion: any) => ({
    ...pricingRegion,
    discount: parseFloat(pricingRegion.discount),
    lengthOfStay: parseFloat(pricingRegion.lengthOfStay),
  }));

const cleanShortBreaks = (shortBreaks: any): IShortBreakArrivalDistribution[] =>
  shortBreaks.map((region: any) => ({
    ...region,
    factor: parseFloat(region.factor),
  }));

const cleanCountries = (countries: any): ICountry[] =>
  countries.map((country: any) => ({
    ...country,
    taxRate: parseFloat(country.taxRate),
  }));

const cleanCalendars = (calendars: any): ICalendar[] =>
  calendars.map((calendar: any) => ({
    ...calendar,
    elasticity: parseFloat(calendar.elasticity),
    distribution: calendar.distribution.map((percentage: any) => ({
      ...percentage,
      value: parseFloat(percentage.value),
    })),
  }));

const cleanMarkup = (markups: any): IMarkup[] =>
  markups.map((markup: any) => ({
    ...markup,
    value: parseFloat(markup.value),
  }));

const cleanCurrency = (currencies: any): ICurrency[] =>
  currencies.map((currency: any) => ({
    ...currency,
    conversionRateToDefault: parseFloat(currency.conversionRateToDefault),
  }));
