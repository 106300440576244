/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { LocationChangeAction, RouterState } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as configuration } from './Configuration';
import { reducer as currency } from './Currency';
import { reducer as downstream } from './Downstream';
import { reducer as engine } from './Engine';
import { reducer as login } from './Login';
import { reducer as quote } from './Quote';
import { RootAction, RootState } from './types';
import { reducer as upstream } from './Upstream';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers: {
  router: Reducer<RouterState, LocationChangeAction>;
}) {
  const rootPersistConfig = {
    key: 'root',
    whitelist: ['login'],
    blacklist: ['configuration'],
    storage,
  };
  const downstreamPersistConfig = {
    key: 'downstream',
    storage,
    blacklist: ['updatesInFlight'],
  };

  const reducer = combineReducers<RootState, RootAction>({
    ...asyncReducers,
    configuration,
    login,
    quote,
    engine,
    upstream,
    currency,
    downstream: persistReducer(downstreamPersistConfig, downstream),
  });

  return persistReducer(rootPersistConfig, reducer);
}
