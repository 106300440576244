import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { updateAvailable } from './actions';

export type ConfigurationAction = ActionType<
  typeof updateAvailable.request | typeof updateAvailable.success | typeof updateAvailable.failure
>;

export type ConfigurationState = Readonly<{
  updateAvailable: boolean;
}>;

const initialState: ConfigurationState = { updateAvailable: false };

const reducer = (state: ConfigurationState = initialState, action: AnyAction) => {
  const typedAction = action as ConfigurationAction;
  switch (typedAction.type) {
    case getType(updateAvailable.success):
      return {
        ...state,
        updateAvailable: true,
      };
    case getType(updateAvailable.failure):
      return {
        ...state,
        updateAvailable: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
