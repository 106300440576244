import { push } from 'connected-react-router';
import ROUTES from 'constants/routes';
import { call, put, takeEvery } from 'redux-saga/effects';

import { setUserCurrency } from 'stateManagement/Currency';
import appDB, { ICountry, ICurrency, IMarkup } from 'services/appDatabase';
import { ActionType, getType } from 'typesafe-actions';
import quote, { setNetIncome } from './actions';

export function* quoteCreateSaga(action: ActionType<typeof quote.create>) {
  const markup: IMarkup = yield appDB.markup.toCollection().first();
  yield put(quote.update({ commissionRate: markup.value }));
  yield put(push(ROUTES.CHARACTERISTICS));
}

function* initialiseQuoteSaga(): any {
  const countriesCollection = yield call([appDB.countries, appDB.countries.toCollection]);
  const country: ICountry = yield call([countriesCollection, countriesCollection.first]);
  const countryCurrency: ICurrency = yield call(
    // @ts-ignore-next-line
    [appDB.currencies, appDB.currencies.get],
    country.currency,
  );

  yield put(setUserCurrency(countryCurrency));
  yield put(setNetIncome(country.defaultPriceType === 'NET'));
}

export default function* quoteSagas() {
  yield takeEvery(getType(quote.create), quoteCreateSaga);
  yield takeEvery([getType(quote.create), getType(quote.loadQuote)], initialiseQuoteSaga);
}
