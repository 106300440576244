import { ILatLon, INewProperty, IUpstreamProperty } from 'services/appDatabase';
import BenchmarkEngine from 'services/engine/benchmark';
import { IBenchmarkEngine } from 'services/engine/types';
import { PricingEngineException } from '../exceptions';

class NovasolBenchmarkEngine extends BenchmarkEngine implements IBenchmarkEngine {
  async getSimilarProperties(newProperty: INewProperty): Promise<IUpstreamProperty[]> {
    const lowerLimit = 1;
    const upperLimit = 1;

    return await this.getDBUpstreamProperties(
      newProperty.bedrooms - lowerLimit,
      newProperty.bedrooms + upperLimit,
      newProperty,
    );
  }

  async getILatLong(newProperty: INewProperty): Promise<ILatLon> {
    if (!newProperty.latitude || !newProperty.longitude) {
      throw new PricingEngineException(
        `Can't fint benchmark properties: no latitude or longitude found for new property`,
      );
    }
    return {
      latitude: newProperty.latitude,
      longitude: newProperty.longitude,
    };
  }

  protected getPropertyFilter(newProperty: INewProperty) {
    return (property: IUpstreamProperty) => property.propertyType === newProperty.propertyType;
  }
}

export default NovasolBenchmarkEngine;
