import _find from 'lodash/find';
import _range from 'lodash/range';
import {
  FeatureValueSet,
  IFeatureValue,
  IInflation,
  INewProperty,
  IPriceCoefficient,
  IUpstreamProperty,
  IWeekMapping,
} from 'services/appDatabase';
import BaseEngine from './base';
import { PricingEngineException } from './exceptions';
import { IPricingEngine, IPricingResult, IYearSummary } from './types';
import { commercialRounding, selectWeekMappingsForYearAndCalendar, weeksInYear } from './utils';

interface IFeatureValueSeason extends IFeatureValue {
  season: string;
}

export interface IWeekData {
  weekNumber: number;
  season: string;
  previousWeek: number;
  previousYear: number;
  year: number;
}

export interface IWeekPricingData extends IWeekData {
  price: number;
  open: boolean;
}

interface IBenchmarkProperty {
  commercialOccupancy: number;
  weightedAdjustedPrice: number;
}

class PricingEngine extends BaseEngine implements IPricingEngine {
  async computePricing(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IPricingResult[]> {
    // eslint-disable-next-lineno-console
    console.log('######################### PRICING ##############################');
    const pricingRegionCode = await this.getPricingRegionCode(newProperty);
    const inflation = await this.db.inflation
      .where('year')
      .equals(newProperty.firstAvailable.year)
      .first();
    const calendar = this.getCalendarName();
    const priceCoefficientsForRegion = await this.getAllRegionalPriceCoefficients(
      pricingRegionCode,
    );
    const priceCoefficients = this.getPriceCoefficients(priceCoefficientsForRegion);

    const [yearOne, yearTwo] = this.getYears(newProperty);

    const yearOneData = await Promise.all(
      (
        await this.buildYearData(yearOne, calendar)
      ).map(async (weekEntry) => {
        const coefficients: FeatureValueSet = priceCoefficients.filter(
          ({ season }) => season === weekEntry.season,
        );

        const benchmarkPropertiesForPricing = await Promise.all(
          benchmarkProperties.map(async (property: IUpstreamProperty) => {
            const weightedCoefficients = this.getPropertyWeightedCoefficients(
              property,
              coefficients,
            );
            const realizedPrice = await this.selectRealizedPrice(
              weekEntry.year,
              weekEntry.weekNumber,
              property,
              calendar,
            );

            if (!realizedPrice) {
              return {
                commercialOccupancy: 0,
                weightedAdjustedPrice: 0,
              };
            }
            const totalFeatureValue = this.sumOfFeatureValueSet(weightedCoefficients);
            const adjustedPrice = realizedPrice - totalFeatureValue;
            const similarityCoefficient = this.getSimilarityCoefficient(newProperty, property);
            const commercialOccupancy = Math.pow(
              property.commercialOccupancy,
              similarityCoefficient,
            );

            return {
              weightedCoefficients, // to remove when Aylin is done testing
              realizedPrice, // to remove when Aylin is done testing
              totalFeatureValue, // to remove when Aylin is done testing
              adjustedPrice, // to remove when Aylin is done testing
              commercialOccupancy, // to remove when Aylin is done testing
              weightedAdjustedPrice: commercialOccupancy * adjustedPrice,
            };
          }),
        );

        const sumOfBenchmarkAdjustedPrices = benchmarkPropertiesForPricing.reduce(
          (acc, value: IBenchmarkProperty) => acc + value.weightedAdjustedPrice,
          0,
        );

        const sumOfBenchmarkCommercialOccupancy = benchmarkPropertiesForPricing.reduce(
          (acc, value: IBenchmarkProperty) => acc + value.commercialOccupancy,
          0,
        );

        const weightedAverageOfAdjustedPrices =
          sumOfBenchmarkAdjustedPrices / sumOfBenchmarkCommercialOccupancy;

        const myPropertyWeightedCoefficients = this.getPropertyWeightedCoefficients(
          newProperty,
          coefficients,
        );

        const myPropertyFeaturesValue = this.sumOfFeatureValueSet(myPropertyWeightedCoefficients);
        let estimatedPrice = weightedAverageOfAdjustedPrices + myPropertyFeaturesValue;
        if (inflation) {
          estimatedPrice += estimatedPrice * (inflation.year1Inflation / 100);
        }

        return {
          ...weekEntry,
          coefficients, // to remove when Aylin is done testing
          myPropertyWeightedCoefficients, // to remove when Aylin is done testing
          benchmarkProperties: benchmarkPropertiesForPricing, // to remove when Aylin is done testing
          weightedAverageOfAdjustedPrices, // to remove when Aylin is done testing
          estimatedPrice, // to remove when Aylin is done testing
          price: Math.max(commercialRounding(estimatedPrice), 0),
          open:
            weekEntry.year > newProperty.firstAvailable.weekYear || // if first available is at the beginning of january, the weekNumber is 53 and the weekYear is year - 1
            newProperty.firstAvailable.weekNumber <= weekEntry.previousWeek,
        };
      }),
    );

    const yearTwoData = await this.buildEstimatedYearData(
      yearTwo,
      calendar,
      yearOneData,
      inflation,
    );

    const result = [
      { year: yearOne, yearData: yearOneData },
      { year: yearTwo, yearData: yearTwoData },
    ];

    // eslint-disable
    console.group('%cPricing result', 'color: #2EA68D; text-decoration: underline;');
    console.log(result);
    console.groupEnd();
    // eslint enable

    return result;
  }

  private getSimilarityCoefficient(newProperty: INewProperty, property: IUpstreamProperty): number {
    const sameBeds = newProperty.bedrooms === property.noOfBedrooms;
    const sameGrade = newProperty.grade === property.grade;
    return 4 - +sameBeds - +sameGrade;
  }

  private getMappedWeek = (yearData: IWeekPricingData[], week: number) =>
    yearData.find((item: IWeekPricingData) => week === item.weekNumber);

  private buildYearData = async (year: number, calendar: string): Promise<IWeekData[]> => {
    const weekMappings = await selectWeekMappingsForYearAndCalendar(year, calendar);
    const weekNumbers = _range(1, (await weeksInYear(year)) + 1);
    return weekNumbers.map((weekNumber: number) => {
      const weekMapping = weekMappings.find((wm: IWeekMapping) => wm.currentWeek === weekNumber);
      if (!weekMapping) {
        throw new PricingEngineException(
          `Cannot find a week mapping for weekNumber: ${weekNumber} year: ${year}`,
        );
      }
      return {
        weekNumber,
        year,
        previousWeek: weekMapping.previousWeek,
        previousYear: weekMapping.previousYear,
        season: weekMapping.season,
      };
    });
  };

  private buildEstimatedYearData = async (
    year: number,
    calendar: string,
    previousYearData: IWeekPricingData[],
    inflation: IInflation | undefined,
  ): Promise<IWeekPricingData[]> =>
    (await this.buildYearData(year, calendar)).map((weekEntry: IWeekData) => {
      const mappedWeek = this.getMappedWeek(previousYearData, weekEntry.previousWeek);
      if (!mappedWeek) {
        throw new PricingEngineException(
          `Cannot find a week mapping from ${year} for weekNumber: ${weekEntry.weekNumber}`,
        );
      }

      let estimatedPrice = mappedWeek.price;
      if (inflation) {
        estimatedPrice +=
          estimatedPrice * ((inflation.year2Inflation - inflation.year1Inflation) / 100);
      }

      return {
        ...weekEntry,
        price: Math.max(commercialRounding(estimatedPrice), 0),
        open: true,
      };
    });

  private async getAllRegionalPriceCoefficients(
    pricingRegion: string,
  ): Promise<IPriceCoefficient[]> {
    const coefficients = await this.db.priceCoefficient
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return coefficients.toArray();
  }

  private getPriceCoefficients(
    priceCoefficientsForRegion: IPriceCoefficient[],
  ): IFeatureValueSeason[] {
    return priceCoefficientsForRegion.map((priceCoefficient) => ({
      season: priceCoefficient.season,
      featureName: priceCoefficient.featureName,
      value: priceCoefficient.coefficient,
    }));
  }

  private selectRealizedPrice = async (
    year: number,
    week: number,
    upstreamProperty: IUpstreamProperty,
    calendar: string,
  ) => {
    let realizedPrice;

    do {
      try {
        const weekMappings = await selectWeekMappingsForYearAndCalendar(year, calendar);
        const weekMapping = weekMappings.find((wm: IWeekMapping) => wm.currentWeek === week);

        if (!weekMapping) {
          console.warn(
            `No realized price found for property #${upstreamProperty.serviceId},week: ${week}`,
          );
          return null;
        }

        week = weekMapping.previousWeek;
        year = weekMapping.previousYear;
      } catch (error) {
        console.warn(
          `No realized price found for property #${upstreamProperty.serviceId},week: ${week}`,
        );
        return null;
      }

      realizedPrice = _find(upstreamProperty.realizedPrices, {
        year,
        weekNumber: week,
      });
    } while (!realizedPrice);

    return realizedPrice.price;
  };
}

export default PricingEngine;

export const getYearSummary = (yearData: IWeekPricingData[]): IYearSummary =>
  yearData.reduce((summary: IYearSummary, week: IWeekPricingData) => {
    const previous = summary.get(week.season);
    summary.set(week.season, {
      highest: previous ? Math.max(previous.highest, week.price) : week.price,
      lowest: previous ? Math.min(previous.lowest, week.price) : week.price,
    });
    return summary;
  }, new Map());
