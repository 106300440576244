import * as Sentry from '@sentry/browser';
import request from 'superagent';
import { getToken, updateToken } from './token';

class Client {
  agent: request.SuperAgentStatic;

  constructor() {
    this.agent = request.agent();
    // @ts-ignore
    this.agent.accept('application/json');
  }

  async request(
    method: 'get' | 'post' | 'put' | 'patch',
    endpoint: string,
    data: object | null,
    followPagination: boolean = false,
  ) {
    let promise = this.agent[method](endpoint);

    const token = getToken();
    if (token) {
      promise = promise.set('Authorization', `Bearer ${token}`);
    }

    if (['post', 'put', 'patch'].includes(method) && data) {
      promise = promise.send(data);
    }

    if (followPagination) {
      let total: any[] = [];

      while (true) {
        const res = await promise;
        const { next, results } = res.body;
        total = total.concat(results);
        if (!next) {
          break;
        }
        promise = this.agent[method](next);
        if (token) {
          promise = promise.set('Authorization', `Bearer ${token}`);
        }
      }

      return total;
    }

    const { body } = await promise;
    return body;
  }

  get(endpoint: string, followPagination: boolean = false) {
    return this.request('get', endpoint, null, followPagination);
  }

  post(endpoint: string, data: object) {
    return this.request('post', endpoint, data);
  }

  put(endpoint: string, data: object) {
    return this.request('put', endpoint, data);
  }

  patch(endpoint: string, data: object) {
    return this.request('patch', endpoint, data);
  }

  async login(endpoint: string, data: object) {
    const result = await this.post(endpoint, data);
    const token: string | undefined = result.token || result.access;
    if (token) {
      updateToken(token);
      Sentry.setUser(await this.get('/auth/users/me/'));
    }
    return token;
  }
}

const client = new Client();

export default client;
