import { DateTime } from 'luxon';
import { ActionType, getType } from 'typesafe-actions';
import uuid from 'uuid';

import { DEFAULT_MILES_TO_SEA_OPTION, SHORTBREAK_OPTIONS } from 'constants/dropdownOptions';
import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import { seasonNames } from 'constants/seasonNames';
import { AnyAction } from 'redux';
import { uploadNewProperty } from 'stateManagement/Downstream/actions';
import { INewProperty } from 'services/appDatabase';
import engineActions from '../Engine/actions';
import quote from './actions';
import { IBrandFeatures } from './features';

export interface QuoteState extends Readonly<INewProperty> {
  features: IBrandFeatures;
  commissionRate: number;
  setNetIncome: boolean;
  persisted: boolean;
}

export type CreateQuoteAction = ActionType<typeof quote.create>;
export type UpdateQuoteAction = ActionType<typeof quote.update>;
export type ResetQuoteAction = ActionType<typeof quote.reset>;
export type LoadQuoteAction = ActionType<typeof quote.loadQuote>;
type DownstreamRequestAction = ActionType<typeof uploadNewProperty.request>;
type DownstreamSuccessAction = ActionType<typeof uploadNewProperty.success>;
type DownstreamFailureAction = ActionType<typeof uploadNewProperty.failure>;
type SaveOwnerWeeksAction = ActionType<typeof quote.saveOwnerWeeks>;
type SetIncomeDisplayAction = ActionType<typeof quote.setNetIncome>;
type EngineStoreRevenueResultAction = ActionType<typeof engineActions.storeRevenueResult>;

const initialState: QuoteState = {
  uuid: '',
  status: QuoteStatus.POPULATING,
  queueStatus: QuoteQueueStatus.QUEUED,
  benchmarkProperties: [],
  pno: '',
  lastName: '',
  firstName: '',
  propertyName: '',
  latitude: 0,
  longitude: 0,
  postcode: '',
  houseNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  propertyType: '',
  bedrooms: 0,
  bathrooms: 0,
  guests: 0,
  grade: 3,
  updatedAt: '',
  persisted: false,
  firstAvailable: DateTime.invalid('Not yet set'),
  goLive: DateTime.invalid('Not yet set'),
  availabilityOpenDate: '',
  availabilityCloseDate: '',
  featureFactors: [],
  ownerWeeks: seasonNames.map((seasonName: string) => ({
    seasonName,
    numberOfWeeks: 0,
  })),
  countryRegion: '',
  subRegion: '',
  cascadeType: '',
  calendar: '',
  pricingRegionCode: null,
  errorMessage: null,
  features: {
    complex: false,
    hasNationalPark: false,
    hasSeaView: false,
    hasSeaside: false,
    hasPrivateHottub: false,
    hasSharedHottub: false,
    hasWifi: false,
    hasPrivateSwimmingPool: false,
    hasSharedSwimmingPool: false,
    hasParking: false,
    hasWoodburner: false,
    isPetFriendly: true,
    isFullyFlexible: true,
    luxury: false,
    milesToSea: DEFAULT_MILES_TO_SEA_OPTION,
    shortBreakAllowed: true,
    shortBreakPartiallyAllowed: false,
    isAllInclusive: false,
    finalCleaning: false,
    isActivityHouse: false,
    internet: '',
    sauna: '',
    fishhouse: '',
    ac: '',
    germanTv: '',
    viewtype: '',
    pooltype: '',
    whirlpool: '',
    buildYear: '',
    propertySize: '',
    distanceToSea: '',
    renovationYear: '',
    longtermStayAllowed: true,
    spar: true,
  },
  commissionRate: 0,
  setNetIncome: false,
  serviceCode: null,
};

const reducer = (state: typeof initialState = initialState, action: AnyAction) => {
  const typedAction = action as
    | CreateQuoteAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | LoadQuoteAction
    | DownstreamSuccessAction
    | DownstreamFailureAction
    | DownstreamRequestAction
    | SaveOwnerWeeksAction
    | SetIncomeDisplayAction
    | EngineStoreRevenueResultAction;
  const dateNow = DateTime.local();
  switch (typedAction.type) {
    case getType(quote.saveOwnerWeeks):
      return {
        ...state,
        ownerWeeks: action.payload,
      };
    case getType(quote.create):
      return {
        ...state,
        ...action.payload,
        uuid: uuid.v4(),
        updatedAt: dateNow.toISO(),
      };
    case getType(quote.update):
      return {
        ...state,
        ...action.payload,
        commissionRate: action.payload.commissionRate
          ? action.payload.commissionRate
          : state.commissionRate,
        features: {
          ...state.features,
          ...(action.payload.features || {}),
          shortBreakAllowed: !!action.payload.shortBreak
            ? action.payload.shortBreak === SHORTBREAK_OPTIONS.FULL_YEAR
            : state.features.shortBreakAllowed,
          shortBreakPartiallyAllowed: !!action.payload.shortBreak
            ? action.payload.shortBreak === SHORTBREAK_OPTIONS.PARTIALLY_ALLOWED
            : state.features.shortBreakPartiallyAllowed,
        },
        goLive: action.payload.goLive || state.goLive,
        firstAvailable: action.payload.firstAvailable || state.firstAvailable,
        updatedAt: dateNow.toISO(),
        queueStatus:
          state.status === QuoteStatus.SUBMITTED ? state.queueStatus : QuoteQueueStatus.QUEUED,
      };
    case getType(engineActions.storeRevenueResult):
      return {
        ...state,
        queueStatus:
          state.status === QuoteStatus.SUBMITTED ? state.queueStatus : QuoteQueueStatus.QUEUED,
      };
    case getType(uploadNewProperty.request):
      return {
        ...state,
        queueStatus:
          // action.payload is the response, which can be different from the reducer state
          action.payload.uuid === state.uuid ? QuoteQueueStatus.UPLOADING : state.queueStatus,
      };
    case getType(uploadNewProperty.success):
      if (action.payload.uuid === state.uuid) {
        return {
          ...state,
          queueStatus:
            state.queueStatus === QuoteQueueStatus.UPLOADING
              ? QuoteQueueStatus.UPLOADED
              : state.queueStatus,
          errorMessage: null,
          serviceCode: action.payload.serviceCode,
          persisted: action.payload.persisted,
        };
      }
      return state;
    case getType(uploadNewProperty.failure):
      return {
        ...state,
        queueStatus:
          action.payload.uuid === state.uuid ? action.payload.newStatus : state.queueStatus,
      };
    case getType(quote.reset):
      return initialState;
    case getType(quote.loadQuote):
      return {
        ...action.payload,
        firstAvailable: DateTime.fromISO(action.payload.firstAvailable),
        goLive: DateTime.fromISO(action.payload.goLive),
      };
    case getType(quote.setNetIncome):
      return {
        ...state,
        setNetIncome: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
