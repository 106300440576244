import { INewProperty } from 'services/appDatabase';
import BaseEngine from './base';
import { PricingEngineException } from './exceptions';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPriceAdjustedOccupancyEngine,
} from './types';

class PriceAdjustedOccupancyEngine extends BaseEngine implements IPriceAdjustedOccupancyEngine {
  async computePriceAdjustedOccupancy(
    newProperty: INewProperty,
    previousOccupancyResults: IOccupancyResult[],
    averagePriceChangeResults: IAveragePriceChangeResult[],
  ): Promise<IOccupancyResult[]> {
    // eslint-disable-next-lineno-console
    console.log('######################### PRICE ADJUSTED OCCUPANCY #############################');
    const { elasticity } = await this.getCalendar();
    const lengthOfStay = await this.getLengthOfStay(newProperty);

    const result = previousOccupancyResults.map((occupancyResult) => {
      const averagePriceChangeResult = averagePriceChangeResults.find(
        (apc) => apc.year === occupancyResult.year,
      );
      if (!averagePriceChangeResult) {
        throw new PricingEngineException(
          `[PriceAdjustedOccupancy] Could not find an average price change for year ${occupancyResult.year}`,
        );
      }
      const adjustedNights = {
        lowest:
          occupancyResult.nights.lowest *
          (1 + averagePriceChangeResult.averagePriceChange * elasticity),
        highest:
          occupancyResult.nights.highest *
          (1 + averagePriceChangeResult.averagePriceChange * elasticity),
      };
      const adjustedBookings = {
        lowest: adjustedNights.lowest / lengthOfStay,
        highest: adjustedNights.highest / lengthOfStay,
      };
      return {
        year: occupancyResult.year,
        nights: adjustedNights,
        bookings: adjustedBookings,
        percentage: occupancyResult.percentage,
      };
    });

    // eslint-disable
    console.group(
      '%cPrice Adjusted Occupancy result',
      'color: #2EA68D; text-decoration: underline;',
    );
    console.log(result);
    console.groupEnd();
    // eslint enable

    return result;
  }
}

export default PriceAdjustedOccupancyEngine;
