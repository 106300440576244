import {
  getAveragePriceChange,
  getFloorPrice,
  getOccupancyResults,
  getPricingResults,
  getProperties,
  getRevenueResults,
  getShortBreakResults,
} from 'stateManagement/Engine/selectors';
import {
  computePropertyFeatureFactors,
  getNewProperty,
  getQuoteCommissionRate,
} from 'stateManagement/Quote/selectors';
import { RootState } from 'stateManagement/types';
import { createSelector } from 'reselect';
import { INewPropertyQuote } from 'services/appDatabase';

const getBenchmarkPropertiesForBackend = createSelector(getProperties, (properties) =>
  properties.map((property) => property.uuid),
);

export const getNewPropertyQuote = createSelector(
  getNewProperty,
  computePropertyFeatureFactors,
  getBenchmarkPropertiesForBackend,
  getOccupancyResults,
  getRevenueResults,
  getPricingResults,
  getAveragePriceChange,
  getShortBreakResults,
  getQuoteCommissionRate,
  getFloorPrice,
  (
    quote,
    featureFactors,
    benchmarkProperties,
    occupancies,
    revenues,
    pricingResults,
    apcResults,
    shortBreakResults,
    commissionRate,
    floorPrice,
  ): INewPropertyQuote => ({
    ...quote,
    firstAvailable: quote.firstAvailable.toISODate(),
    goLive: quote.goLive.toISODate(),
    availabilityOpenDate: quote.availabilityOpenDate || null,
    availabilityCloseDate: quote.availabilityCloseDate || null,
    grade: quote.grade || 0,
    floorPrice,
    featureFactors,
    benchmarkProperties,
    occupancies,
    shortBreakResults,
    revenues,
    pricingResults,
    apcResults,
    commissionRate,
  }),
);

export const getIsFetchingDownstream = (state: RootState) => state.downstream.fetching;
export const getIsFetchedDownstream = (state: RootState) => state.downstream.fetched;
export const getDownstreamUpdatesInFlight = (state: RootState) => state.downstream.updatesInFlight;
