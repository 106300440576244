import _range from 'lodash/range';
import { DateTime } from 'luxon';
import appDatabase from './appDatabase';

export const getThursdays = async (year: number) => {
  const weekDates = await appDatabase.weekDates.where('year').equals(year).sortBy('week');
  return weekDates.map(({ date }) => DateTime.fromISO(date).toFormat('d LLL'));
};

export const getWeekDate = (year: number, weekNumber: number) => {
  if (weekNumber < 1 || weekNumber > 53) {
    throw new Error('Weeknumber should be between 1 and 53');
  }
  const firstThursday = DateTime.fromObject({ weekYear: year, weekNumber: 1, weekday: 4 });
  return firstThursday.plus({ weeks: weekNumber - 1 }).toFormat('d LLL');
};

export const getYearOne = (yearArray: Array<{ year: number }>) =>
  Math.min(...yearArray.map((e) => e.year));
