import { RootState } from 'stateManagement/types';
import { createSelector } from 'reselect';
import { FeatureValueSet, INewProperty } from 'services/appDatabase';
import { parseCottages } from './features';
import { QuoteState } from './reducer';

export const getRawQuote = (state: RootState): QuoteState => state.quote;

export const getQuoteTitle = (state: RootState) => {
  if (state.quote.updatedAt) {
    const updatedAt = new Date(state.quote.updatedAt).toLocaleDateString();
    return `Quote: ${state.quote.propertyName} | ${updatedAt} `;
  }
  return `Quote: ${state.quote.propertyName} | ${new Date()} `;
};

export const getQuoteFeatures = createSelector(getRawQuote, (quote) => quote.features);
export const getQuoteUuid = createSelector(getRawQuote, (quote) => quote.uuid);

export const getFirstDateAvailable = createSelector(getRawQuote, (quote) => quote.firstAvailable);
export const getGoLiveDate = createSelector(getRawQuote, (quote) => quote.goLive);

// NOTE: cottages.com specific
export const computePropertyFeatureFactors = createSelector(
  getRawQuote,
  (quote: QuoteState): FeatureValueSet => parseCottages(quote),
);

export const getNewProperty = createSelector(
  getRawQuote,
  computePropertyFeatureFactors,
  (quote: QuoteState, features: FeatureValueSet): INewProperty => ({
    ...quote,
    featureFactors: features,
  }),
);

export const getOwnerWeeks = createSelector(getRawQuote, (quote) => quote.ownerWeeks);

export const getQuoteStatus = createSelector(getRawQuote, (quote) => quote.status);

export const getQuoteCommissionRate = createSelector(getRawQuote, (quote) => quote.commissionRate);

export const getSetNetIncome = createSelector(getRawQuote, (quote) => quote.setNetIncome);
