import { RADIUS } from 'constants/engine';
import _find from 'lodash/find';
import { DateTime } from 'luxon';
import appDatabase from 'services/appDatabase';
import { ILatLon, ISeason, IWeekMapping } from '../appDatabase';
import { PricingEngineException } from './exceptions';

export const computeDistance = (pointA: ILatLon, pointB: ILatLon): number => {
  const dLat = deg2rad(pointB.latitude - pointA.latitude);
  const dLon = deg2rad(pointB.longitude - pointA.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(pointA.latitude)) *
      Math.cos(deg2rad(pointB.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.floor(RADIUS * c);
};

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const getPercentile = (array: number[], percentile: number) => {
  const sortedArray = array.slice().sort((a, b) => a - b);
  const index = array.length * percentile;
  return index % 1 === 0
    ? (sortedArray[index - 1] + sortedArray[index]) / 2
    : sortedArray[Math.floor(index)];
};

export const getLeadTime = (elementDate: DateTime, goLiveDate: DateTime) => {
  const diffInMonthsGoLive = Math.ceil(elementDate.diff(goLiveDate, 'months').as('months'));
  return Math.max(0, diffInMonthsGoLive);
};

export const getPostcodeSector = (postcode: string): string => {
  const sector = postcode.trim().toUpperCase().replace(' ', '');
  return sector.substring(0, sector.length - 2);
};

export const weeksInYear = (year: number): Promise<number> => {
  return appDatabase.weekDates.where('year').equals(year).count();
};

export const roundEstimatedPrice = (price: number, percentage: number, rounding: number) =>
  Math.round((price * (1 + percentage)) / rounding) * rounding;

export const commercialRounding = (price: number) => {
  const lastDigit = price % 10;
  const allOtherDigits = Math.floor(price / 10) * 10;
  return allOtherDigits + (lastDigit <= 5 ? 5 : 9);
};

export const getSeasons = async (): Promise<ISeason[]> => await appDatabase.season.toArray();

export const getSeasonData = (allSeasons: ISeason[], weekMapping: IWeekMapping) => {
  const seasonObject = _find(allSeasons, ['uuid', weekMapping.season]);
  if (!seasonObject) {
    throw new Error("Couldn't find a season with this uuid: " + weekMapping.season);
  }
  return { seasonName: seasonObject.name, seasonColor: seasonObject.color };
};

export const selectWeekMappingsForYearAndCalendar = async (
  year: number,
  calendarName: string,
): Promise<IWeekMapping[]> => {
  let res: IWeekMapping[] = [];
  const MAX_YEARS = 3;

  for (let pastYears = 0; pastYears < MAX_YEARS; pastYears++) {
    res = await appDatabase.weekMapping
      .where('[currentYear+bookingType+calendarName]')
      .equals([year - pastYears, 'Fullweek', calendarName])
      .sortBy('currentWeek');

    if (res.length > 0) {
      return res;
    }
  }

  throw new PricingEngineException(
    `No week mappings found between ${year - MAX_YEARS} and ${year}`,
  );
};
