import { DateTime } from 'luxon';
import { INewProperty } from 'services/appDatabase';
import OccupancyEngine from 'services/engine/occupancy';
import { IOccupancyEngine } from 'services/engine/types';
import { getBigPropertyRegion } from './utils';

class CottagesOccupancyEngine extends OccupancyEngine implements IOccupancyEngine {
  async getPricingRegionCode(property: INewProperty): Promise<string> {
    const bigPropertyRegion = getBigPropertyRegion(property);
    return bigPropertyRegion || super.getPricingRegionCode(property);
  }

  protected getAvailableNightsPerYear(newProperty: INewProperty) {
    if (!newProperty.availabilityOpenDate || !newProperty.availabilityCloseDate) {
      return super.getAvailableNightsPerYear(newProperty);
    }

    const openDate = DateTime.fromISO(newProperty.availabilityOpenDate);
    const closeDate = DateTime.fromISO(newProperty.availabilityCloseDate);
    return closeDate.diff(openDate).as('days');
  }
}

export default CottagesOccupancyEngine;
