import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import { eventChannel } from 'redux-saga';
import {
  call,
  debounce,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  computeAll,
  selectProperties,
  storeAPCResult,
  storeBaseOccupancy,
  storeFinalOccupancy,
  storePricingResult,
  storeRevenueResult,
  storeShortBreakResult,
} from 'stateManagement/Engine';

import { REHYDRATE } from 'redux-persist';
import quoteActions from 'stateManagement/Quote/actions';
import appDB, { INewPropertyQuote } from 'services/appDatabase';
import client from 'services/networking/request';
import { ActionType, getType } from 'typesafe-actions';
import {
  dequeuePropertiesManualRequest,
  downloadNewProperties,
  uploadNewProperty,
} from './actions';
import { formatQuote } from './format';
import { getNewPropertyQuote } from './selectors';

const getQueuedOrUploadingOrFailedProperties = async (): Promise<INewPropertyQuote[]> =>
  await appDB.newProperties
    .where('queueStatus')
    .anyOf([QuoteQueueStatus.UPLOADING, QuoteQueueStatus.QUEUED, QuoteQueueStatus.FAILED])
    .toArray();

function* updateDownstreamDataSaga(): any {
  if (!navigator.onLine) {
    console.debug('%c🔌 Offline:' + 'not downloading new properties.', 'font-weight: bold;');
    yield put(downloadNewProperties.success({}));
    return;
  }

  const pendingProperties: INewPropertyQuote[] = yield call(getQueuedOrUploadingOrFailedProperties);
  const pendingPropertiesUUIDS = pendingProperties.map((p) => p.uuid);

  const downstreamPropertiesEndpoint = '/api/downstream/new_property/';
  const newProperties = yield call([client, client.get], downstreamPropertiesEndpoint);
  const cleanedNewProperties = cleanNewProperties(newProperties);

  const backendPropertiesWithoutPendingOnes = cleanedNewProperties.filter(
    (property) => !pendingPropertiesUUIDS.includes(property.uuid),
  );

  const propertiesToAdd = [...backendPropertiesWithoutPendingOnes, ...pendingProperties];

  try {
    yield call([appDB.newProperties, appDB.newProperties.clear]);
    yield call([appDB.newProperties, appDB.newProperties.bulkAdd], propertiesToAdd);
    yield put(downloadNewProperties.success({}));
  } catch (error) {
    // eslint-disable-next-lineno-console
    console.error('[Downstream request] error:', error);
    yield put(
      downloadNewProperties.failure({ errorMessage: '[Download] Failed to add new properties' }),
    );
  }
}

const getPropertiesToUpload = async () =>
  // @ts-ignore
  await appDB.newProperties
    .where('[queueStatus+status]')
    .anyOf([
      [QuoteQueueStatus.QUEUED, QuoteStatus.DELETED],
      [QuoteQueueStatus.QUEUED, QuoteStatus.DRAFT],
      [QuoteQueueStatus.QUEUED, QuoteStatus.SUBMITTED],
    ])
    .toArray();

export function* uploadDownstreamDataSaga(
  action: ActionType<typeof uploadNewProperty.request>,
): any {
  const quote = action.payload;

  try {
    yield call([appDB.newProperties, appDB.newProperties.update], quote.uuid, {
      queueStatus: QuoteQueueStatus.UPLOADING,
    });
    console.debug(
      `%c⬆️ Uploading property ${quote.uuid.substr(0, 6)}` + '...',
      'font-weight: bold;',
    );

    let newPropertyResponse;

    if (!quote.persisted) {
      newPropertyResponse = yield call(
        [client, client.post],
        `/api/downstream/new_property/`,
        formatQuote(quote),
      );
    } else {
      newPropertyResponse = yield call(
        [client, client.put],
        `/api/downstream/new_property/${quote.uuid}/`,
        formatQuote(quote),
      );
    }

    console.debug(
      `%c⬆️ Uploading property ${quote.uuid.substr(0, 6)}: ` + '%csuccess ✅',
      'font-weight: bold;',
    );

    // if the quote being updated is in the reducer, the reducer will update its state
    yield put(
      uploadNewProperty.success({
        uuid: quote.uuid,
        status: newPropertyResponse.status,
        serviceCode: newPropertyResponse.serviceCode as string,
        persisted: newPropertyResponse.persisted as boolean,
      }),
    );

    yield call([appDB.newProperties, appDB.newProperties.update], quote.uuid, {
      queueStatus: QuoteQueueStatus.UPLOADED,
      status: newPropertyResponse.status,
      errorMessage: null,
      serviceCode: newPropertyResponse.serviceCode as string,
      persisted: newPropertyResponse.persisted as boolean,
    });
  } catch (error) {
    console.debug('TCL: function*uploadDownstreamDataSaga -> error', error);
    console.debug(
      `%c⬆️ Uploading property ${quote.uuid.substr(0, 6)}: ` + '%cfailed ❌: ' + error.message,
      'font-weight: bold;',
    );
    let queueStatus: QuoteQueueStatus = QuoteQueueStatus.QUEUED;
    let status: QuoteStatus = quote.status;
    let errorMessage: string = "Error while saving quote. Will try again when we're back online";

    if (error.status === 400) {
      // Don't attempt to re-send failed quotes.
      console.debug(
        `%c⬆️ Uploading property ${quote.uuid.substr(0, 6)}: ` + "%cwon't retry until next change",
        'font-weight: bold;',
      );
      queueStatus = QuoteQueueStatus.FAILED;
      status = QuoteStatus.DRAFT;
      errorMessage = Object.keys(error.response.body)
        .flatMap((key) => error.response.body[key])
        .join(' ');
    }
    yield put(
      uploadNewProperty.failure({
        uuid: quote.uuid,
        errorMessage,
        queueStatus,
      }),
    );
    yield call([appDB.newProperties, appDB.newProperties.update], quote.uuid, {
      queueStatus,
      status,
      errorMessage,
    });
  }
}

const connectionChannel = (event: string) =>
  eventChannel((emitter: any) => {
    const handleFunction = () => emitter(navigator.onLine);
    window.addEventListener(event, handleFunction);
    return () => window.removeEventListener(event, handleFunction);
  });

function* dequeuePropertiesManualSaga() {
  if (navigator.onLine) {
    yield delay(1000);
    const properties: INewPropertyQuote[] = yield call(getPropertiesToUpload);
    console.debug(
      '%c👫 Dequeuing:' + `%cselected ${properties.length} properties from the store`,
      'font-weight: bold;',
    );
    for (const property of properties) {
      yield put(uploadNewProperty.request(property));
    }
    console.debug(
      '%c👫 Dequeuing:' + `%cprocessed ${properties.length} properties`,
      'font-weight: bold;',
    );
  }
}

function* dequeuePropertiesWhenOnline(): any {
  const onlineChannel = yield call(connectionChannel, 'online');

  yield take(REHYDRATE);

  if (navigator.onLine) {
    console.debug(
      '%c💡 Initially online:' + '%cstarting dequeuing process...',
      'font-weight: bold;',
    );
    yield call(dequeuePropertiesManualSaga);
  }
  while (true) {
    yield take(onlineChannel);
    console.debug('%c💡 Online:' + '%cstarting dequeuing process...', 'font-weight: bold;');
    yield call(dequeuePropertiesManualSaga);
  }
}

function* saveToIndexedDB() {
  const newPropertyQuote: INewPropertyQuote = yield select(getNewPropertyQuote);
  if (newPropertyQuote.uuid !== '') {
    yield call([appDB.newProperties, appDB.newProperties.put], newPropertyQuote);
  }

  yield put(dequeuePropertiesManualRequest());
}

export default function* downstreamSagas() {
  yield takeEvery(getType(uploadNewProperty.request), uploadDownstreamDataSaga);
  yield takeLatest(getType(downloadNewProperties.request), updateDownstreamDataSaga);
  yield fork(dequeuePropertiesWhenOnline);

  // Continuously save current quote in IndexedDB
  yield debounce(
    500,
    [
      quoteActions.create,
      quoteActions.update,
      selectProperties.success,
      computeAll,
      storePricingResult,
      storeShortBreakResult,
      storeAPCResult,
      storeBaseOccupancy,
      storeFinalOccupancy,
      storeRevenueResult,
      quoteActions.saveOwnerWeeks,
    ].map(getType),
    saveToIndexedDB,
  );

  yield takeEvery(dequeuePropertiesManualRequest, dequeuePropertiesManualSaga);

  yield takeEvery(getType(uploadNewProperty.request), saveToIndexedDB);
}

const cleanNewProperties = (rawProperties: any): INewPropertyQuote[] =>
  rawProperties.map((property: any) => ({
    ...JSON.parse(property.rawQuoteState),
    queueStatus: QuoteQueueStatus.UPLOADED,
    errorMessage: null,
    serviceCode: property.serviceCode,
    persisted: property.persisted,
  }));
