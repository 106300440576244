import { Duration } from 'luxon';
import LoadingModal from 'molecules/LoadingModal';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'stateManagement/types';
import { getIsFetching } from 'stateManagement/Upstream/selectors';

// 15 minutes
const estimatedDuration = Duration.fromMillis(15 * 60 * 1000);

interface IProps {
  loading: boolean;
}

const UpstreamLoadingModal = ({ loading }: IProps) => {
  return (
    <LoadingModal
      estimatedDuration={estimatedDuration}
      open={loading}
      title="Loading application data..."
    />
  );
};

export default connect((state: RootState) => ({
  loading: getIsFetching(state),
}))(UpstreamLoadingModal);
