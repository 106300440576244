import UpdateRequiredModal from 'organisms/UpdateRequiredModal/update.wrap';
import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ConnectedRouter } from 'connected-react-router';
import { History, UnregisterCallback } from 'history';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { muiTheme } from 'stylesheet';

import UpdatesInFlightModal from 'organisms/UpdatesInFlightModal';
import UpstreamLoadingModal from 'organisms/UpstreamLoadingModal';
import { ConnectedCurrencyProvider } from './context/Currency';
import AppCrashFallback from './molecules/AppCrashFallback';
import ErrorBoundary from './molecules/ErrorBoundary';
import Routes from './routes';
import { instrumentation } from 'services/instrumentation';

interface IProps {
  history: History;
  persistor: Persistor;
  store: Store;
}

// By default ChartDataLabels registers itself for all charts
Chart.plugins.unregister(ChartDataLabels);

class App extends React.PureComponent<IProps> {
  unlisten?: UnregisterCallback;

  wentOffline() {
    instrumentation.sendEvent('wentOffline');
  }

  cameOnline() {
    instrumentation.sendEvent('cameOnline');
  }

  routeChanged() {
    instrumentation.sendEvent('routeChanged');
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.routeChanged);
    window.addEventListener('online', this.cameOnline);
    window.addEventListener('offline', this.wentOffline);
  }

  componentWillUnmount() {
    this.unlisten && this.unlisten();
    window.removeEventListener('online', this.cameOnline);
    window.removeEventListener('offline', this.wentOffline);
  }

  render() {
    const { history, persistor, store } = this.props;

    return (
      <ErrorBoundary FallbackComponent={AppCrashFallback}>
        <Provider store={store as any}>
          <MuiThemeProvider theme={muiTheme}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedCurrencyProvider>
                <UpdatesInFlightModal />
                <UpstreamLoadingModal />
                <UpdateRequiredModal />
                <ConnectedRouter history={history}>
                  <Route path="/" component={Routes} />
                </ConnectedRouter>
              </ConnectedCurrencyProvider>
            </PersistGate>
          </MuiThemeProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
