import { MILES_TO_SEA_OPTIONS } from 'constants/dropdownOptions';
import { FeatureValueSet } from 'services/appDatabase';
import { QuoteState } from './reducer';

export interface CottagesFeatures {
  complex: boolean;
  hasNationalPark: boolean;
  hasSeaView: boolean;
  hasSeaside: boolean;
  hasPrivateHottub: boolean;
  hasSharedHottub: boolean;
  hasWifi: boolean;
  hasPrivateSwimmingPool: boolean;
  hasSharedSwimmingPool: boolean;
  hasParking: boolean;
  hasWoodburner: boolean;
  luxury: boolean;
  isPetFriendly: boolean;
  isFullyFlexible: boolean;
  milesToSea: string;
  shortBreakAllowed: boolean;
  shortBreakPartiallyAllowed: boolean;
}

export interface NovasolFeatures {
  isAllInclusive: boolean;
  finalCleaning: boolean;
  isPetFriendly: boolean;
  isActivityHouse: boolean;
  internet: string;
  sauna: string;
  fishhouse: string;
  ac: string;
  germanTv: string;
  viewtype: string;
  pooltype: string;
  whirlpool: string;
  buildYear: string;
  propertySize: string;
  distanceToSea: string;
  renovationYear: string;
  longtermStayAllowed: boolean;
  spar: boolean;
}

export type IBrandFeatures = CottagesFeatures & NovasolFeatures & { winBackServiceCode?: string };

const bool2int = (b: boolean): number => +b;

const getMilesFromSeaClusterValue = (milesFromSea: string) => {
  switch (milesFromSea) {
    case MILES_TO_SEA_OPTIONS.MORE_THAN_FIVE:
      return 0;
    case MILES_TO_SEA_OPTIONS.WITHIN_FIVE:
      return 0.333;
    case MILES_TO_SEA_OPTIONS.WITHIN_THREE:
      return 0.666;
    case MILES_TO_SEA_OPTIONS.WITHIN_ONE:
      return 1;
    default:
      return 0;
  }
};

export const parseCottages = (quote: QuoteState): FeatureValueSet => [
  {
    featureName: 'bathrooms_bedrooms_ratio',
    value: quote.bathrooms / (quote.bedrooms === 0 ? 1 : quote.bedrooms),
  },
  { featureName: 'bedrooms_1', value: bool2int(quote.bedrooms === 1) },
  { featureName: 'bedrooms_2', value: bool2int(quote.bedrooms === 2) },
  { featureName: 'bedrooms_3', value: bool2int(quote.bedrooms === 3) },
  { featureName: 'bedrooms_4', value: bool2int(quote.bedrooms === 4) },
  { featureName: 'bedrooms_5', value: bool2int(quote.bedrooms === 5) },
  { featureName: 'bedrooms_6', value: bool2int(quote.bedrooms === 6) },
  { featureName: 'bedrooms_7', value: bool2int(quote.bedrooms === 7) },
  { featureName: 'bedrooms_8', value: bool2int(quote.bedrooms === 8) },
  { featureName: 'bedrooms_9', value: bool2int(quote.bedrooms === 9) },
  { featureName: 'bedrooms_10', value: bool2int(quote.bedrooms >= 10) },
  {
    featureName: 'sleeps_per_bedrooms',
    value: quote.guests / (quote.bedrooms === 0 ? 1 : quote.bedrooms),
  },
  { featureName: 'has_carparking', value: bool2int(quote.features.hasParking) },
  { featureName: 'is_complex', value: bool2int(quote.features.complex) },
  { featureName: 'is_epic', value: 1 },
  { featureName: 'grade', value: quote.grade ? (quote.grade - 3) / 2 : 0 },
  {
    featureName: 'hottub_privacy',
    value: quote.features.hasPrivateHottub ? 1 : quote.features.hasSharedHottub ? 0.5 : 0,
  },
  { featureName: 'is_fullyflexible', value: bool2int(quote.features.isFullyFlexible) },
  { featureName: 'is_luxury', value: bool2int(quote.features.luxury) },
  {
    featureName: 'miles_from_sea_cluster_percentage',
    value: getMilesFromSeaClusterValue(quote.features.milesToSea),
  },
  { featureName: 'in_nationalpark', value: bool2int(quote.features.hasNationalPark) },
  { featureName: 'is_pet_friendly', value: bool2int(quote.features.isPetFriendly) },
  { featureName: 'has_sea_view', value: bool2int(quote.features.hasSeaView) },
  { featureName: 'short_breaks_allowed', value: bool2int(quote.features.shortBreakAllowed) },
  {
    featureName: 'short_breaks_partially_allowed',
    value: bool2int(quote.features.shortBreakPartiallyAllowed),
  },
  {
    featureName: 'has_swimming_pool',
    value: quote.features.hasPrivateSwimmingPool
      ? 1
      : quote.features.hasSharedSwimmingPool
      ? 0.5
      : 0,
  },
  { featureName: 'has_wifi', value: bool2int(quote.features.hasWifi) },
  { featureName: 'has_woodburningstove', value: bool2int(quote.features.hasWoodburner) },
];
