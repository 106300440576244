import { IToggles } from 'pages/PricingRange/PricingRange';

const reportError = (error: Error, errorInfo?: Record<string, any>) => {
  if (window.newrelic) {
    window.newrelic.noticeError(error, errorInfo);
  }
  // eslint-disable-next-line
  console.error(error);
};

type EventName =
  | 'dialogOpened'
  | 'ownerDocumentDownloadAttempted'
  | 'dialogSubmitted'
  | 'dialogCancelled'
  | 'routeChanged'
  | 'cameOnline'
  | 'wentOffline'
  | 'toggleToggled';

type DialogName =
  | 'estimateAdjustments'
  | 'ownerWeeks'
  | 'seasonalOpenCloseDates'
  | 'saveAdjustments'
  | 'priceReason';

type ToggleName = keyof IToggles;
type DialogEventAttributes = Record<'dialogName', DialogName>;
type ToggleEventAttributes = Record<'toggleName', ToggleName>;

type EventAttributes = DialogEventAttributes | ToggleEventAttributes;

const sendEvent = (eventName: EventName, attributes?: EventAttributes) => {
  if (window.newrelic) {
    window.newrelic.addPageAction(eventName, attributes);
  }
};

const instrumentation = { reportError, sendEvent };
export { instrumentation };
