import { INewProperty, IShortBreakArrivalDistribution } from 'services/appDatabase';
import ShortBreakEngine from 'services/engine/shortBreak';
import { IShortBreakEngine } from 'services/engine/types';
import { BIG_PROPERTIES } from '../constants';
import { PricingEngineException } from '../exceptions';
import { getBigPropertyRegion } from './utils';

class CottagesPricingEngine extends ShortBreakEngine implements IShortBreakEngine {
  async getPricingRegionCode(property: INewProperty): Promise<string> {
    const bigPropertyRegion = getBigPropertyRegion(property);
    return bigPropertyRegion || super.getPricingRegionCode(property);
  }

  async getShortBreakArrivalDistribution(
    pricingRegionCode: string,
    bedrooms: number,
  ): Promise<IShortBreakArrivalDistribution[]> {
    // for large properties, we have only one value for the short break distribution
    // This is stored under the min bed value for big properties
    const isLargeProperty =
      bedrooms > BIG_PROPERTIES.MIN_BED_NO && pricingRegionCode === BIG_PROPERTIES.CODE;
    if (isLargeProperty) {
      const shortBreak = await this.db.shortBreak
        .where('[pricingRegionCode+bedrooms]')
        .equals([BIG_PROPERTIES.CODE, BIG_PROPERTIES.MIN_BED_NO])
        .and((shortbreak) => ['weekend', 'midweek'].includes(shortbreak.type.type));

      if (!shortBreak) {
        throw new PricingEngineException(
          `No short break data found in database for pricing region code: ${BIG_PROPERTIES.MIN_BED_NO} and bedrooms: ${BIG_PROPERTIES.CODE}`,
        );
      }
      return shortBreak.toArray();
    }
    return super.getShortBreakArrivalDistribution(pricingRegionCode, bedrooms);
  }
}

export default CottagesPricingEngine;
