import {
  APP_PAGE_COUNTER,
  APP_PAGE_COUNTER_FLAG,
  APP_PAGE_COUNTER_FLAG_VALUE,
  MAX_ALLOWED_PAGE_RELOADS,
} from 'constants/site';

const incrementPageCounter = (): number => {
  const pageCounter = parseInt(localStorage.getItem(APP_PAGE_COUNTER) || '0', 10) + 1;
  localStorage.setItem(APP_PAGE_COUNTER, String(pageCounter));
  return pageCounter;
};

const isOnLine = (): boolean => {
  return navigator.onLine;
};

const canReloadPage = (pageCounter: number): boolean => {
  return pageCounter >= MAX_ALLOWED_PAGE_RELOADS && isOnLine();
};

const resetCounter = () => {
  localStorage.setItem(APP_PAGE_COUNTER, '0');
};

const setPageReloadTrigger = () => {
  localStorage.setItem(APP_PAGE_COUNTER_FLAG, APP_PAGE_COUNTER_FLAG_VALUE);
};

const isPageReloadTriggerSet = (): boolean => {
  return localStorage.getItem(APP_PAGE_COUNTER_FLAG) !== null;
};

const resetStoredItems = () => {
  localStorage.removeItem(APP_PAGE_COUNTER_FLAG);
  resetCounter();
};

export const isAfterSoftPageReload = (): boolean => {
  if (isPageReloadTriggerSet()) {
    resetStoredItems();
    return true;
  }
  return false;
};

export const isSoftReloadRequired = (): boolean => {
  const pageCounter = incrementPageCounter();
  return canReloadPage(pageCounter);
};

export const reloadPage = (): void => {
  resetCounter();
  setPageReloadTrigger();
  window.location.reload();
};
