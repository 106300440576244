import appDatabase, { ISector, IUpstreamProperty } from './appDatabase';

export const storeProperties = async (rawProperties: any) => {
  const properties = cleanPropertyData(rawProperties);
  await appDatabase.upstreamProperties.bulkAdd(properties);
};

export const storeSectors = async (rawSectors: any) => {
  const cleanedSectors = cleanSectors(rawSectors);
  await appDatabase.sectors.bulkAdd(cleanedSectors);
};

const cleanPropertyData = (properties: IUpstreamProperty[]): IUpstreamProperty[] =>
  properties.map((property: any) => ({
    ...property,
    bathroomsBedroomsRatio: parseFloat(property.bathroomsBedroomsRatio),
    commercialOccupancy: parseFloat(property.commercialOccupancy),
    gradeNormalized: parseFloat(property.gradeNormalized),
    hottubPrivacy: parseFloat(property.hottubPrivacy),
    milesFromSeaClusterPercentage: parseFloat(property.milesFromSeaClusterPercentage),
    sleepsPerBedrooms: parseFloat(property.sleepsPerBedrooms),
    swimmingPool: parseFloat(property.swimmingPool),
    novasolSeasonAPrice: parseFloat(property.novasolSeasonAPrice),
    featureFactors: property.featureFactors.map((featureFactor: any) => ({
      featureName: featureFactor.feature,
      value: parseFloat(featureFactor.factor),
    })),
    realizedPrices: property.realizedPrices.map((realizedPrice: any) => ({
      ...realizedPrice,
      price: parseFloat(realizedPrice.price),
      weekendRatio: parseFloat(realizedPrice.weekendRatio),
      midweekRatio: parseFloat(realizedPrice.midweekRatio),
    })),
  }));

const cleanSectors = (sectors: any): ISector[] =>
  sectors.map((sector: any) => ({
    ...sector,
    latitude: parseFloat(sector.latitude),
    longitude: parseFloat(sector.longitude),
  }));
