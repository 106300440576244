import _range from 'lodash/range';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export interface IOptions {
  label: string;
  value: number | string;
}

export interface IDropdown {
  bedrooms: number | string;
  bathrooms: number | string;
  guests: number | string;
}

export const SHORTBREAK_OPTIONS = {
  FULL_YEAR: 'fullYear',
  PARTIALLY_ALLOWED: 'partiallyAllowed',
  NOT_ALLOWED: 'notAllowed',
};

export const DEFAULT_SHORTBREAK_OPTION = SHORTBREAK_OPTIONS.FULL_YEAR;

export const getShortBreakOptions = (): IOptions[] => [
  { label: 'Full year', value: SHORTBREAK_OPTIONS.FULL_YEAR },
  { label: 'Partially allowed', value: SHORTBREAK_OPTIONS.PARTIALLY_ALLOWED },
  { label: 'Not allowed', value: SHORTBREAK_OPTIONS.NOT_ALLOWED },
];

export const MILES_TO_SEA_OPTIONS = {
  MORE_THAN_FIVE: '+ 5 miles',
  WITHIN_FIVE: 'within 5 miles',
  WITHIN_THREE: 'within 3 miles',
  WITHIN_ONE: 'within 1 miles',
};

export const DEFAULT_MILES_TO_SEA_OPTION = MILES_TO_SEA_OPTIONS.MORE_THAN_FIVE;

export const getMilesToSeaOptions = (): IOptions[] => [
  { label: '+ 5 miles', value: MILES_TO_SEA_OPTIONS.MORE_THAN_FIVE },
  { label: 'within 5 miles', value: MILES_TO_SEA_OPTIONS.WITHIN_FIVE },
  { label: 'within 3 miles', value: MILES_TO_SEA_OPTIONS.WITHIN_THREE },
  { label: 'within 1 miles', value: MILES_TO_SEA_OPTIONS.WITHIN_ONE },
];

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(2)};
  margin-bottom: ${getSpacing(4)};
  width: 200px;
`;

export const USERS = {
  BDM: 'BDM',
  OWNER: 'OWNER',
};

export interface IReason {
  label: string;
  value: number;
  adjustedBy: string;
  code: string;
}
export const REASONS_OPTIONS: IReason[] = [
  { code: 'LG_PROP', label: 'Large property', value: 1, adjustedBy: USERS.BDM },
  { code: 'UNQ_LOC', label: 'Unique location', value: 2, adjustedBy: USERS.BDM },
  { code: 'UNQ_PROP', label: 'Unique property', value: 3, adjustedBy: USERS.BDM },
  { code: 'EXC_QLT', label: 'Exceptional quality', value: 4, adjustedBy: USERS.BDM },
  { code: 'CMPT', label: 'Competition in area', value: 5, adjustedBy: USERS.BDM },
  { code: 'OWN_BLF', label: 'Owners belief', value: 6, adjustedBy: USERS.OWNER },
  { code: 'OWN_ND', label: 'Owners need', value: 7, adjustedBy: USERS.OWNER },
  { code: 'MTCH_TRMS', label: 'Match current terms', value: 8, adjustedBy: USERS.BDM },
];

export const OWNER_REASONS_OPTIONS = REASONS_OPTIONS.filter(
  (reason) => reason.adjustedBy === USERS.OWNER,
);

export const BDM_REASONS_OPTIONS = REASONS_OPTIONS.filter(
  (reason) => reason.adjustedBy === USERS.BDM,
);

export const getFirstAvailableOptions = () => {
  const now = new Date();
  const currentYear = now.getFullYear();

  return [
    { label: currentYear.toString(), value: `${currentYear}-01-01` },
    { label: (currentYear + 1).toString(), value: `${currentYear + 1}-01-01` },
  ];
};
