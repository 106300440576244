import { createAsyncAction } from 'typesafe-actions';

export const upstreamData = createAsyncAction(
  'UPSTREAM_DATA/UPSTREAM_DATA_REQUEST',
  'UPSTREAM_DATA/UPSTREAM_DATA_SUCCESS',
  'UPSTREAM_DATA/UPSTREAM_DATA_FAILURE',
)<
  {},
  {},
  {
    errorMessage: string;
  }
>();

export default {
  upstreamData,
};
