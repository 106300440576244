import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface IProps {
  open: boolean;
}

export const UpdateRequiredModal = ({ open }: IProps) => {
  function closeModalAndRefresh() {
    window.location.replace(window.origin);
  }
  return (
    <Dialog
      aria-labelledby="update-modal-title"
      open={open}
      maxWidth="xs"
      fullWidth
      data-test="cy-loading-modal"
    >
      <DialogTitle disableTypography id="update-modal-title">
        <Typography align="center" variant="h2">
          HOST update available
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button onClick={closeModalAndRefresh} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
