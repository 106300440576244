import { DeepPartial } from 'redux';
import { EngineState } from 'stateManagement/Engine';
import { INewProperty, INewPropertyQuote, IOwnerWeeks } from 'services/appDatabase';
import { createStandardAction } from 'typesafe-actions';

interface IUpdateQuote extends DeepPartial<INewProperty> {
  commissionRate?: number;
}

export const create = createStandardAction('QUOTE/CREATE')<INewProperty>();
export const update = createStandardAction('QUOTE/UPDATE_FEATURES')<IUpdateQuote>();
export const reset = createStandardAction('QUOTE/RESET')<{}>();
export const saveOwnerWeeks = createStandardAction('QUOTE/SAVE_OWNER_WEEKS')<IOwnerWeeks[]>();
export const loadQuote = createStandardAction('QUOTE/LOAD_QUOTE')<INewPropertyQuote>();
export const loadQuoteEngine = createStandardAction('QUOTE/LOAD_QUOTE_ENGINE')<EngineState>();
export const setNetIncome = createStandardAction('QUOTE/SET_NET_INCOME')<boolean>();

export default {
  create,
  update,
  reset,
  saveOwnerWeeks,
  loadQuote,
  loadQuoteEngine,
  setNetIncome,
};
