import { INewProperty, IShortBreakArrivalDistribution } from 'services/appDatabase';
import averagePriceChange from 'services/engine/averagePriceChange';
import { IAveragePriceChangeEngine } from 'services/engine/types';
import { BIG_PROPERTIES } from '../constants';
import { getBigPropertyRegion } from './utils';

class CottagesAveragePriceChange extends averagePriceChange implements IAveragePriceChangeEngine {
  protected async getPricingRegionCode(property: INewProperty): Promise<string> {
    const bigPropertyRegion = getBigPropertyRegion(property);
    return bigPropertyRegion || super.getPricingRegionCode(property);
  }

  protected async getShortBreakArrivalDistribution(
    pricingRegionCode: string,
    bedrooms: number,
  ): Promise<IShortBreakArrivalDistribution[]> {
    const newPropertyBedrooms = Math.min(bedrooms, BIG_PROPERTIES.MIN_BED_NO);
    return super.getShortBreakArrivalDistribution(pricingRegionCode, newPropertyBedrooms);
  }
}

export default CottagesAveragePriceChange;
