import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { upstreamData } from './actions';

export type UpstreamAction = ActionType<
  typeof upstreamData.success | typeof upstreamData.failure | typeof upstreamData.request
>;

export type UpstreamState = Readonly<{
  fetched: boolean;
  fetching: boolean;
}>;

const initialState: UpstreamState = { fetched: false, fetching: false };

const reducer = (state: UpstreamState = initialState, action: AnyAction) => {
  const typedAction = action as UpstreamAction;
  switch (typedAction.type) {
    case getType(upstreamData.request):
      return { fetched: false, fetching: true };
    case getType(upstreamData.success):
      return { fetched: true, fetching: false };
    case getType(upstreamData.failure):
      return { fetched: false, fetching: false };
    default:
      return state;
  }
};

export default reducer;
