import { AppDatabase } from 'services/appDatabase';
import AveragePriceChangeEngine from 'services/engine/averagePriceChange';
import Engine from 'services/engine/engine';
import PriceAdjustedOccupancyEngine from 'services/engine/priceAdjustedOccupancy';
import PricingEngine from 'services/engine/pricing';
import RevenueEngine from 'services/engine/revenue';
import ShortBreakEngine from 'services/engine/shortBreak';
import OccupancyEngine from '../occupancy';
import NovasolBenchmarkEngine from './benchmark';

export default (database: AppDatabase) =>
  new Engine(
    database,
    NovasolBenchmarkEngine,
    OccupancyEngine,
    PricingEngine,
    RevenueEngine,
    ShortBreakEngine,
    AveragePriceChangeEngine,
    PriceAdjustedOccupancyEngine,
  );
