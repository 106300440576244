import { AppDatabase, INewProperty, IUpstreamProperty } from 'services/appDatabase';
import BaseEngine from './base';
import {
  IAveragePriceChangeEngine,
  IAveragePriceChangeResult,
  IBenchmarkEngine,
  IOccupancyEngine,
  IOccupancyResult,
  IPriceAdjustedOccupancyEngine,
  IPricingEngine,
  IPricingResult,
  IRevenueEngine,
  IRevenueResult,
  IShortBreakEngine,
  IShortBreakResult,
  IStayTypeValue,
} from './types';

type IBenchmarkEngineConstructor = new (database: AppDatabase) => IBenchmarkEngine;
type IOccupancyEngineConstructor = new (database: AppDatabase) => IOccupancyEngine;
type IPricingEngineConstructor = new (database: AppDatabase) => IPricingEngine;
type IRevenueEngineConstructor = new (database: AppDatabase) => IRevenueEngine;
type IShortBreakEngineConstructor = new (database: AppDatabase) => IShortBreakEngine;
type IAveragePriceChangeEngineConstructor = new (
  database: AppDatabase,
) => IAveragePriceChangeEngine;
type IPriceAdjustedOccupancyEngineConstructor = new (
  database: AppDatabase,
) => IPriceAdjustedOccupancyEngine;

class TotalEngine extends BaseEngine {
  private benchmarkEngine: IBenchmarkEngine;
  private occupancyEngine: IOccupancyEngine;
  private pricingEngine: IPricingEngine;
  private revenueEngine: IRevenueEngine;
  private shortBreakEngine: IShortBreakEngine;
  private averagePriceChangeEngine: IAveragePriceChangeEngine;
  private priceAdjustedOccupancyEngine: IPriceAdjustedOccupancyEngine;

  constructor(
    database: AppDatabase,
    BenchmarkEngine: IBenchmarkEngineConstructor,
    OccupancyEngine: IOccupancyEngineConstructor,
    PricingEngine: IPricingEngineConstructor,
    RevenueEngine: IRevenueEngineConstructor,
    ShortBreakEngine: IShortBreakEngineConstructor,
    AveragePriceChangeEngine: IAveragePriceChangeEngineConstructor,
    PriceAdjustedOccupancyEngine: IPriceAdjustedOccupancyEngineConstructor,
  ) {
    super(database);
    this.benchmarkEngine = new BenchmarkEngine(this.db);
    this.occupancyEngine = new OccupancyEngine(this.db);
    this.pricingEngine = new PricingEngine(this.db);
    this.revenueEngine = new RevenueEngine(this.db);
    this.shortBreakEngine = new ShortBreakEngine(this.db);
    this.averagePriceChangeEngine = new AveragePriceChangeEngine(this.db);
    this.priceAdjustedOccupancyEngine = new PriceAdjustedOccupancyEngine(this.db);
  }

  public selectBenchmarkProperties(newProperty: INewProperty): Promise<IUpstreamProperty[]> {
    return this.benchmarkEngine.compute(newProperty);
  }

  public computeOccupancy(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IOccupancyResult[]> {
    return this.occupancyEngine.computeOccupancy(newProperty, benchmarkProperties);
  }

  public computePricing(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IPricingResult[]> {
    return this.pricingEngine.computePricing(newProperty, benchmarkProperties);
  }

  public computeRevenue(
    pricingResults: IPricingResult[],
    occupancyResult: IOccupancyResult[],
    newProperty: INewProperty,
  ): Promise<IRevenueResult[]> {
    return this.revenueEngine.computeRevenue(pricingResults, occupancyResult, newProperty);
  }

  public computeShortBreak(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    pricingResult: IPricingResult[],
    priceDistribution?: IStayTypeValue[] | undefined,
  ): Promise<IShortBreakResult> {
    return this.shortBreakEngine.computeShortBreak(
      newProperty,
      benchmarkProperties,
      pricingResult,
      priceDistribution,
    );
  }

  public computeAPC(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    ownerShortBreak: IShortBreakResult,
    bdmPricing: IPricingResult[],
  ): Promise<IAveragePriceChangeResult[]> {
    return this.averagePriceChangeEngine.computeAveragePriceChange(
      newProperty,
      benchmarkProperties,
      ownerShortBreak,
      bdmPricing,
    );
  }

  public computePriceAdjustedOccupancy(
    newProperty: INewProperty,
    previousOccupancyResults: IOccupancyResult[],
    averagePriceChangeResults: IAveragePriceChangeResult[],
  ): Promise<IOccupancyResult[]> {
    return this.priceAdjustedOccupancyEngine.computePriceAdjustedOccupancy(
      newProperty,
      previousOccupancyResults,
      averagePriceChangeResults,
    );
  }
}

export default TotalEngine;
