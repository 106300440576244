import { ILatLon, INewProperty, IUpstreamProperty } from 'services/appDatabase';
import BenchmarkEngine from 'services/engine/benchmark';
import { IBenchmarkEngine } from 'services/engine/types';

class CottagesBenchmarkEngine extends BenchmarkEngine implements IBenchmarkEngine {
  async getSimilarProperties(newProperty: INewProperty): Promise<IUpstreamProperty[]> {
    const numberOfRooms = newProperty.bedrooms;
    let lowerLimit = 1;
    let upperLimit = 1;

    // for a 4 bedroom property, the benchmark properties only have 3 or 4 bedrooms (not 5)
    if (numberOfRooms === 4) {
      upperLimit = 0;
    }
    // for a 5 bedroom property, the benchmark properties only have 5 or 6 bedrooms (not 4)
    if (numberOfRooms === 5) {
      lowerLimit = 0;
    }

    // for a 10+ bedrooms property, I benchmark against 9, 10 and 10+ bedrooms (any number of bedroom above 10)
    if (numberOfRooms >= 10) {
      lowerLimit = numberOfRooms - 9;
      upperLimit = 99 - numberOfRooms;
    }

    return await this.getDBUpstreamProperties(
      numberOfRooms - lowerLimit,
      numberOfRooms + upperLimit,
      newProperty,
    );
  }

  async getILatLong(newProperty: INewProperty): Promise<ILatLon> {
    if (newProperty.latitude && newProperty.longitude) {
      return {
        latitude: newProperty.latitude,
        longitude: newProperty.longitude,
      };
    }
    return await this.getSector(newProperty.postcode);
  }

  protected getPropertyFilter(newProperty: INewProperty) {
    return (property: IUpstreamProperty) =>
      property.grade >= newProperty.grade - 1 && property.grade <= newProperty.grade + 1;
  }
}

export default CottagesBenchmarkEngine;
