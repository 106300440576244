import _flatten from 'lodash/flattenDeep';
import _map from 'lodash/map';
import _round from 'lodash/round';
import { AdjustedResult, AdjustedResultItem } from 'stateManagement/Engine';
import { INewPropertyQuote, ISeasonalSummary } from 'services/appDatabase';
import { getYearOne } from 'services/dates';
import { getYearSummary } from 'services/engine/pricing';
import { IPricingResult } from 'services/engine/types';
import { enableForSite } from 'services/site';
import { round } from 'services/utils/numberUtils';

export const formatQuote = (newProperty: INewPropertyQuote) => {
  return enableForSite('cottages')
    ? formatQuoteCottages(newProperty)
    : formatQuoteNovasol(newProperty);
};

// Add rounding to seasonalSummary and week prices
const formatQuoteNovasol = (newProperty: INewPropertyQuote) => {
  const pricingResults = formatAdjustments(newProperty.pricingResults, (item) => ({ item })).map(
    (line) =>
      line.item.data.map((pricing: any) => {
        const seasonalSummary: ISeasonalSummary[] = [];
        const yearSummary = getYearSummary(pricing.yearData);
        yearSummary.forEach((season, key) => {
          seasonalSummary.push({
            season: key,
            high: round(season.highest, 0),
            low: round(season.lowest, 0),
          });
        });

        const weekPricings = pricing.yearData.map((weekPrice: any) => ({
          weekNumber: weekPrice.weekNumber,
          price: round(weekPrice.price, 0),
        }));

        return {
          ...line,
          year: pricing.year,
          weekPricings,
          seasonalSummary,
        };
      }),
  );

  return {
    ...formatCommon(newProperty),
    pricingResults: _flatten(pricingResults),
  };
};

export const cleanUnusedDataFromPricingResult = (
  pricingResult: undefined | AdjustedResultItem<IPricingResult[]>,
): null | IPricingResult[] => {
  if (pricingResult) {
    return pricingResult.data.map((year: IPricingResult) => {
      return {
        year: year.year,
        yearData: year.yearData.map((week) => {
          return {
            ...week,
            benchmarkProperties: [],
            coefficients: [],
            myPropertyWeightedCoefficients: [],
          };
        }),
      };
    });
  }
  return null;
};

const formatQuoteCottages = (newProperty: INewPropertyQuote) => {
  const pricingResults = formatAdjustments(newProperty.pricingResults, (item) => ({ item })).map(
    (line) =>
      line.item.data.map((pricing: any) => {
        const seasonalSummary: ISeasonalSummary[] = [];
        const yearSummary = getYearSummary(pricing.yearData);
        yearSummary.forEach((season, key) => {
          seasonalSummary.push({
            season: key,
            high: season.highest,
            low: season.lowest,
          });
        });

        return {
          ...line,
          year: pricing.year,
          weekPricings: pricing.yearData,
          seasonalSummary,
        };
      }),
  );

  const newEnginePricingResults = cleanUnusedDataFromPricingResult(
    newProperty.pricingResults.engine,
  );
  const newBdmPricingResults = cleanUnusedDataFromPricingResult(newProperty.pricingResults.bdm);
  const newOwnerPricingResults = cleanUnusedDataFromPricingResult(newProperty.pricingResults.owner);
  const newAdjustedPricingResults = cleanUnusedDataFromPricingResult(
    newProperty.pricingResults.adjusted,
  );

  if (newEnginePricingResults && newProperty.pricingResults.engine) {
    newProperty.pricingResults.engine.data = newEnginePricingResults;
  }
  if (newBdmPricingResults && newProperty.pricingResults.bdm) {
    newProperty.pricingResults.bdm.data = newBdmPricingResults;
  }
  if (newOwnerPricingResults && newProperty.pricingResults.owner) {
    newProperty.pricingResults.owner.data = newOwnerPricingResults;
  }
  if (newAdjustedPricingResults && newProperty.pricingResults.adjusted) {
    newProperty.pricingResults.adjusted.data = newAdjustedPricingResults;
  }

  return {
    ...formatCommon(newProperty),
    pricingResults: _flatten(pricingResults),
    rawQuoteState: JSON.stringify(newProperty),
  };
};

const formatCommon = (newProperty: INewPropertyQuote) => {
  const yearOne = getYearOne(newProperty.pricingResults.engine.data);
  return {
    ...newProperty,
    featureFactors: newProperty.featureFactors.map((ff) => ({
      feature: ff.featureName,
      factor: _round(ff.value, 2),
    })),
    revenues: newProperty.revenues.map((x) => ({
      ...x,
      highest: round(x.highest, 2),
      lowest: round(x.lowest, 2),
    })),
    occupancyResults: formatAdjustments(newProperty.occupancies, (item) => ({
      items: item.data.map((x) => ({
        ...x,
        nightHigh: round(x.nights.highest, 4),
        nightLow: round(x.nights.lowest, 4),
        bookingHigh: round(x.bookings.highest, 4),
        bookingLow: round(x.bookings.lowest, 4),
      })),
    })),
    shortbreakResults: formatAdjustments(newProperty.shortBreakResults, (item) => ({
      shortbreakResultItems: item.data.priceDistribution,
    })),
    ownerWeeksPerSeason: newProperty.ownerWeeks.map((ownerWeek) => ({
      ...ownerWeek,
      season: ownerWeek.seasonName,
    })),
    commissionRate: round(newProperty.commissionRate, 2),
    shortbreakWeeklyRatio: newProperty.shortBreakResults.engine.data.weeks
      .filter((week) => week.year === yearOne)
      .map((week) => ({
        weekNumber: week.weekNumber,
        midweekRatio: week.averageMidweekRatio,
        weekendRatio: week.averageWeekendRatio,
      })),
  };
};

const formatAdjustments = <T>(
  adjustedResult: AdjustedResult<T>,
  extra: (adjustedItem: AdjustedResultItem<T>) => any,
) => {
  return _map(adjustedResult as any, (adjustedItem: AdjustedResultItem<T>, adjustmentType) => ({
    adjustmentType: adjustmentType.toUpperCase(),
    reason: adjustedItem.reason,
    note: adjustedItem.note,
    adjustmentReasons: adjustedItem.adjustmentReasons,
    ...extra(adjustedItem),
  }));
};
