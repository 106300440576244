import { DateTime } from 'luxon';
import {
  FeatureValueSet,
  IBookingPerMonth,
  IBookingWindowPenalty,
  INewProperty,
  IOccupancyCoefficient,
  IUpstreamProperty,
} from '../appDatabase';

import BaseEngine from './base';
import { IOccupancyEngine, IOccupancyResult, IRange } from './types';
import { getLeadTime, getPercentile } from './utils';

interface IMonthlyOccupancy {
  open: boolean;
  leadTime: number;
  bookingPenalty: number;
  year: number;
  month: number;
  commercialOccupancy: IRange;
}

class OccupancyEngine extends BaseEngine implements IOccupancyEngine {
  private lowestPercentile = 0.5;
  private highestPercentile = 0.7;

  public async computeOccupancy(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IOccupancyResult[]> {
    const lengthOfStay = await this.getLengthOfStay(newProperty);
    const pricingRegionCode = await this.getPricingRegionCode(newProperty);

    // eslint-disable-next-lineno-console
    console.log(`Using property region code: ${pricingRegionCode}`);

    // eslint-disable-next-lineno-console
    console.group('%cOccupancy details', 'color: #2EA68D; text-decoration: underline;');

    // eslint-disable-next-lineno-console
    console.log('benchmarkProperties', benchmarkProperties);

    const occupancyCoefficients = await this.selectOccupancyCoefficients(pricingRegionCode);
    const myPropertyWeightedCoefficients: FeatureValueSet =
      await this.getPropertyWeightedCoefficients(newProperty, occupancyCoefficients);

    // eslint-disable-next-lineno-console
    console.log('myPropertyWeightedCoefficients', myPropertyWeightedCoefficients);

    const benchmarkPropertiesWeightedCoefficients: FeatureValueSet[] = benchmarkProperties.map(
      (property: IUpstreamProperty) =>
        this.getPropertyWeightedCoefficients(property, occupancyCoefficients),
    );

    // eslint-disable-next-lineno-console
    console.log('benchmarkPropertiesWeightedCoefficients', benchmarkPropertiesWeightedCoefficients);

    const benchmarkPropertiesSummedCoefficients: number[] =
      benchmarkPropertiesWeightedCoefficients.map(this.sumOfFeatureValueSet);
    const benchmarkPropertiesAdjustedOccupancies = benchmarkProperties.map(
      (property: IUpstreamProperty, index: number) =>
        property.commercialOccupancy - benchmarkPropertiesSummedCoefficients[index],
    );

    // eslint-disable-next-lineno-console
    console.log('benchmarkPropertiesSummedCoefficients', benchmarkPropertiesSummedCoefficients);
    // eslint-disable-next-lineno-console
    console.log('benchmarkPropertiesAdjustedOccupancies', benchmarkPropertiesAdjustedOccupancies);

    const adjustedOccupancy: IRange = {
      lowest: getPercentile(benchmarkPropertiesAdjustedOccupancies, this.lowestPercentile),
      highest: getPercentile(benchmarkPropertiesAdjustedOccupancies, this.highestPercentile),
    };

    // eslint-disable-next-lineno-console
    console.log('adjustedOccupancy', adjustedOccupancy);

    const myPropertySummedCoefficients = this.sumOfFeatureValueSet(myPropertyWeightedCoefficients);

    // eslint-disable-next-lineno-console
    console.log('myPropertySummedCoefficients', myPropertySummedCoefficients);

    const estimatedOccupancy: IRange = {
      lowest: Math.min(myPropertySummedCoefficients + adjustedOccupancy.lowest, 1),
      highest: Math.min(myPropertySummedCoefficients + adjustedOccupancy.highest, 1),
    };

    // eslint-disable-next-lineno-console
    console.log('estimatedOccupancy', estimatedOccupancy);

    const availableNights = this.getAvailableNightsPerYear(newProperty);
    // eslint-disable-next-lineno-console
    console.log('availableNightsPerYear', availableNights);

    const commercialOccupancy: IRange = {
      lowest: estimatedOccupancy.lowest * availableNights,
      highest: estimatedOccupancy.highest * availableNights,
    };

    // eslint-disable-next-lineno-console
    console.log('commercialOccupancy', commercialOccupancy);

    const bookingPerMonth = await this.selectBookingPerMonth(pricingRegionCode);
    const monthlyExpectedNoOfNights = bookingPerMonth.map((bookingsPerMonth: IBookingPerMonth) => ({
      month: bookingsPerMonth.month,
      expectedNoOfNights: {
        lowest: bookingsPerMonth.percentageOfNights * commercialOccupancy.lowest,
        highest: bookingsPerMonth.percentageOfNights * commercialOccupancy.highest,
      },
    }));

    const [thisYear, nextYear] = this.getYears(newProperty);

    const expectedNoOfNightsForTwoYears = [
      ...monthlyExpectedNoOfNights.map((expectedNights) => ({
        ...expectedNights,
        year: thisYear,
      })),
      ...monthlyExpectedNoOfNights.map((expectedNights) => ({
        ...expectedNights,
        year: nextYear,
      })),
    ];

    const bookingPenalties = expectedNoOfNightsForTwoYears.map((element) => {
      const elementDate = DateTime.local(element.year, element.month, 1);
      const leadTime = getLeadTime(elementDate, newProperty.goLive);
      const startMonth = elementDate.month;
      return this.selectPenalty(startMonth, leadTime);
    });

    const allBookingWindowPenalties = await Promise.all(bookingPenalties);

    const monthlyOccupancyRange: IMonthlyOccupancy[] = expectedNoOfNightsForTwoYears.map(
      (element, index) => {
        const elementDate = DateTime.local(element.year, element.month, 1);
        const bookingWindow = allBookingWindowPenalties[index];
        const open = elementDate >= newProperty.firstAvailable;
        const bookingPenalty = bookingWindow ? bookingWindow.penalty : 0;
        return {
          ...element,
          open,
          leadTime: getLeadTime(elementDate, newProperty.goLive),
          bookingPenalty,
          commercialOccupancy: {
            lowest: open ? bookingPenalty * element.expectedNoOfNights.lowest : 0,
            highest: open ? bookingPenalty * element.expectedNoOfNights.highest : 0,
          },
        };
      },
    );

    // eslint-disable-next-lineno-console
    console.log('monthlyOccupancyRange', monthlyOccupancyRange);

    const yearlyOccupancyRangeNights = [
      {
        year: thisYear,
        nights: this.getYearlyCommercialOccupancy(monthlyOccupancyRange, thisYear),
      },
      {
        year: nextYear,
        nights: this.getYearlyCommercialOccupancy(monthlyOccupancyRange, nextYear),
      },
    ];

    const occupancyResults = yearlyOccupancyRangeNights.map((occupancyRange) => ({
      ...occupancyRange,
      bookings: {
        highest: occupancyRange.nights.highest / lengthOfStay,
        lowest: occupancyRange.nights.lowest / lengthOfStay,
      },
    }));

    // eslint-disable-next-lineno-console
    console.groupEnd();

    // eslint-disable-next-lineno-console
    console.log('occupancyResults', occupancyResults);

    return occupancyResults;
  }

  protected getAvailableNightsPerYear(newProperty: INewProperty): number {
    return newProperty.firstAvailable.daysInYear;
  }

  private async selectOccupancyCoefficients(
    pricingRegion: string,
  ): Promise<IOccupancyCoefficient[]> {
    const coefficients = await this.db.occupancyCoefficients
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return coefficients.toArray();
  }

  private async selectBookingPerMonth(pricingRegion: string): Promise<IBookingPerMonth[]> {
    const bookingPerMonth = await this.db.bookingPerMonth
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return bookingPerMonth.toArray();
  }

  private async selectPenalty(
    startMonth: number,
    leadTime: number,
  ): Promise<IBookingWindowPenalty | undefined> {
    const bookingPenalty = await this.db.bookingWindowPenalty
      .where('[departMonth+leadTime]')
      .equals([startMonth, leadTime])
      .first();

    return bookingPenalty;
  }

  private getYearlyCommercialOccupancy = (
    monthlyOccupancyList: IMonthlyOccupancy[],
    year: number,
  ): IRange =>
    monthlyOccupancyList.reduce(
      (acc: IRange, value: IMonthlyOccupancy) => ({
        lowest: acc.lowest + (value.year === year ? value.commercialOccupancy.lowest : 0),
        highest: acc.highest + (value.year === year ? value.commercialOccupancy.highest : 0),
      }),
      { lowest: 0, highest: 0 },
    );
}

export default OccupancyEngine;
