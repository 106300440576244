import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import Dexie from 'dexie';
import { DateTime } from 'luxon';
import { AdjustedResult } from 'stateManagement/Engine';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPricingResult,
  IRevenueResult,
  IShortBreakResult,
  IWeekDate,
} from './engine/types';

export interface IAppDatabase {
  upstreamProperties: Dexie.Table<IUpstreamProperty, string>;
  newProperties: Dexie.Table<INewPropertyQuote, string>;
  sectors: Dexie.Table<ISector, string>;
  occupancyCoefficients: Dexie.Table<IOccupancyCoefficient, number>;
  features: Dexie.Table<IFeature, string>;
  bookingPerMonth: Dexie.Table<IBookingPerMonth, number>;
  bookingWindowPenalty: Dexie.Table<IBookingWindowPenalty, number>;
  weekMapping: Dexie.Table<IWeekMapping, string>;
  season: Dexie.Table<ISeason, string>;
  priceCoefficient: Dexie.Table<IPriceCoefficient, string>;
  bookingProbability: Dexie.Table<IBookingProbability, number>;
  pricingRegion: Dexie.Table<IPricingRegion, number>;
  shortBreak: Dexie.Table<IShortBreakArrivalDistribution, number>;
  calendar: Dexie.Table<ICalendar, string>;
  propertyTypes: Dexie.Table<IPropertyType, string>;
  countries: Dexie.Table<ICountry, string>;
  countryRegions: Dexie.Table<ICountryRegion, string>;
  markup: Dexie.Table<IMarkup, string>;
  cascadeTypes: Dexie.Table<ICascadeType, string>;
  cascadeData: Dexie.Table<ICascadeData, string>;
  currencies: Dexie.Table<ICurrency, string>;
  weekDates: Dexie.Table<IWeekDate, string>;
  inflation: Dexie.Table<IInflation, number>;
}

export class AppDatabase extends Dexie implements IAppDatabase {
  upstreamProperties: Dexie.Table<IUpstreamProperty, string>;
  newProperties: Dexie.Table<INewPropertyQuote, string>;
  sectors: Dexie.Table<ISector, string>;
  occupancyCoefficients: Dexie.Table<IOccupancyCoefficient, number>;
  features: Dexie.Table<IFeature, string>;
  bookingPerMonth: Dexie.Table<IBookingPerMonth, number>;
  bookingWindowPenalty: Dexie.Table<IBookingWindowPenalty, number>;
  weekMapping: Dexie.Table<IWeekMapping, string>;
  season: Dexie.Table<ISeason, string>;
  priceCoefficient: Dexie.Table<IPriceCoefficient, string>;
  bookingProbability: Dexie.Table<IBookingProbability, number>;
  pricingRegion: Dexie.Table<IPricingRegion, number>;
  shortBreak: Dexie.Table<IShortBreakArrivalDistribution, number>;
  calendar: Dexie.Table<ICalendar, string>;
  propertyTypes: Dexie.Table<IPropertyType, string>;
  countries: Dexie.Table<ICountry, string>;
  countryRegions: Dexie.Table<ICountryRegion, string>;
  markup: Dexie.Table<IMarkup, string>;
  cascadeTypes: Dexie.Table<ICascadeType, string>;
  cascadeData: Dexie.Table<ICascadeData, string>;
  currencies: Dexie.Table<ICurrency, string>;
  weekDates: Dexie.Table<IWeekDate, string>;
  inflation: Dexie.Table<IInflation, number>;

  allTables: Array<
    Dexie.Table<
      | IProperty
      | INewPropertyQuote
      | ISector
      | IOccupancyCoefficient
      | IFeature
      | IBookingPerMonth
      | IBookingWindowPenalty
      | IWeekMapping
      | ISeason
      | IPriceCoefficient
      | IPropertyType
      | IBookingProbability
      | IPricingRegion
      | IShortBreakArrivalDistribution
      | ICalendar
      | ICountry
      | ICountryRegion
      | IMarkup
      | ICascadeType
      | ICascadeData
      | ICurrency
      | IWeekDate
      | IInflation,
      string | number
    >
  >;

  constructor() {
    super('AppDatabase');
    this.version(1).stores({
      upstreamProperties: 'uuid,noOfBedrooms',
      newProperties: 'uuid',
      sectors: 'sector',
      features: 'name',
      occupancyCoefficients: '++, regionName',
      bookingPerMonth: '++, regionCode',
      bookingWindowPenalty: '++,[departMonth+leadTime]',
    });
    this.version(2).stores({ weekMapping: '[currentYear+currentWeek]' });
    this.version(3).stores({ season: 'uuid' });
    this.version(4).stores({
      calendarEntry: '++, calendarName, [season+weekNumber+calendarName]',
      weekMapping: '++, [currentYear+currentWeek]',
    });
    this.version(5).stores({ priceCoefficient: '++, regionCode' });
    this.version(6).stores({ bookingProbability: '++, [regionCode+year]' });
    this.version(7).stores({ weekMapping: '++, [currentYear+bookingType]' });
    this.version(8).stores({ bookingProbability: '++, regionCode' });
    this.version(9).stores({ region: '++, code' });
    this.version(10).stores({ shortBreak: '++, [regionCode+bedrooms]' });
    this.version(11).stores({ calendar: 'uuid, name' });
    this.version(12).stores({
      weekMapping: '++, [currentYear+bookingType+calendarName]',
      calendarEntry: null,
    });
    this.version(13).stores({ propertyTypes: '++' });
    this.version(14).stores({ countries: 'uuid, code' });
    this.version(15).stores({ countryRegions: 'uuid' });
    this.version(16).stores({ markup: 'uuid' });
    this.version(17).stores({
      region: null,
      pricingRegion: '++, code',
      shortBreak: '++, [pricingRegionCode+bedrooms]',
      bookingProbability: '++, pricingRegionCode',
      priceCoefficient: '++, pricingRegionCode',
      occupancyCoefficients: '++, pricingRegionName',
      bookingPerMonth: '++, pricingRegionCode',
    });
    this.version(18).stores({ cascadeTypes: 'uuid,calendar' });
    this.version(19).stores({ cascadeData: 'uuid,cascadeType' });
    this.version(20).stores({ occupancyCoefficients: '++, pricingRegionCode' });
    this.version(21).stores({ currencies: 'uuid' });
    this.version(22).stores({ newProperties: 'uuid,status' });
    this.version(23).stores({ newProperties: 'uuid, status, [queueStatus+status]' });
    this.version(24).stores({ newProperties: 'uuid, status, [queueStatus+status], queueStatus' });
    this.version(25).stores({ weekDates: 'uuid, year' });
    this.version(26).stores({ inflation: 'year' });

    this.upstreamProperties = this.table('upstreamProperties');
    this.newProperties = this.table('newProperties');
    this.sectors = this.table('sectors');
    this.occupancyCoefficients = this.table('occupancyCoefficients');
    this.features = this.table('features');
    this.bookingPerMonth = this.table('bookingPerMonth');
    this.bookingWindowPenalty = this.table('bookingWindowPenalty');
    this.weekMapping = this.table('weekMapping');
    this.season = this.table('season');
    this.priceCoefficient = this.table('priceCoefficient');
    this.bookingProbability = this.table('bookingProbability');
    this.pricingRegion = this.table('pricingRegion');
    this.shortBreak = this.table('shortBreak');
    this.calendar = this.table('calendar');
    this.propertyTypes = this.table('propertyTypes');
    this.countries = this.table('countries');
    this.countryRegions = this.table('countryRegions');
    this.markup = this.table('markup');
    this.cascadeTypes = this.table('cascadeTypes');
    this.cascadeData = this.table('cascadeData');
    this.currencies = this.table('currencies');
    this.weekDates = this.table('weekDates');
    this.inflation = this.table('inflation');

    this.allTables = [
      this.upstreamProperties,
      this.newProperties,
      this.sectors,
      this.occupancyCoefficients,
      this.features,
      this.bookingPerMonth,
      this.bookingWindowPenalty,
      this.weekMapping,
      this.season,
      this.priceCoefficient,
      this.bookingProbability,
      this.pricingRegion,
      this.shortBreak,
      this.calendar,
      this.propertyTypes,
      this.countries,
      this.countryRegions,
      this.markup,
      this.cascadeTypes,
      this.cascadeData,
      this.currencies,
      this.weekDates,
      this.inflation,
    ];
  }

  dangerousClearDB = () => Promise.all(this.allTables.map((table) => table.clear()));

  clearDBExcept = (tablesToFilterOut: string[]) =>
    Promise.all(
      this.allTables
        .filter((table) => !tablesToFilterOut.includes(table.name))
        .map((table) => table.clear()),
    );
}

export interface IProperty {
  featureFactors: FeatureValueSet;
  grade: number;
  postcode: string;
}

export interface IUpstreamProperty extends IProperty {
  uuid: string;
  serviceId: number;
  serviceCode: string;
  createdAt: string;
  updatedAt: string;
  distance?: number;
  realizedPrices: IRealizedPrice[];
  longitude: string;
  latitude: string;
  noOfBedrooms: number;
  commercialOccupancy: number;
  propertyType: string;
  novasolSeasonAPrice: number | null;
}

export interface ISeasonalSummary {
  season: string;
  high: number;
  low: number;
}

export interface IInflation {
  year: number;
  year1Inflation: number;
  year2Inflation: number;
}

interface INewPropertyBase extends IProperty {
  uuid: string;
  pno: string;
  lastName: string;
  firstName: string;
  propertyName: string;
  bedrooms: number;
  bathrooms: number;
  guests: number;
  persisted: boolean;
  updatedAt: string;
  benchmarkProperties: string[];
  status: QuoteStatus;
  queueStatus: QuoteQueueStatus;
  ownerWeeks: IOwnerWeeks[];
  houseNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  propertyType: string;
  longitude: number | null;
  latitude: number | null;
  features: IBrandFeatures;
  countryRegion: string;
  subRegion: string;
  pricingRegionCode: string | null;
  serviceCode: string | null;
  winBackServiceCode?: string;
  errorMessage: string | null;
}

export interface INewProperty extends INewPropertyBase {
  firstAvailable: DateTime;
  goLive: DateTime;
  availabilityOpenDate: string;
  availabilityCloseDate: string;
  calendar: string;
  cascadeType: string;
}

export interface INewPropertyQuote extends INewPropertyBase {
  floorPrice: number | null;
  pricingResults: AdjustedResult<IPricingResult[]>;
  shortBreakResults: AdjustedResult<IShortBreakResult>;
  occupancies: AdjustedResult<IOccupancyResult[]>;
  revenues: IRevenueResult[];
  apcResults: IAveragePriceChangeResult[];
  firstAvailable: string;
  goLive: string;
  commissionRate: number;
  availabilityOpenDate: string | null;
  availabilityCloseDate: string | null;
}

export interface IFeatureValue {
  featureName: string;
  value: number;
}

export type FeatureValueSet = IFeatureValue[];

export interface ILatLon {
  latitude: number;
  longitude: number;
}

export interface ISector extends ILatLon {
  sector: string;
  pricingRegionName: string;
  pricingRegionCode: string;
  lengthOfStay: number;
}

export interface IOccupancyCoefficient extends IFeatureValue {
  pricingRegionCode: string;
}

export interface IPricingCoefficients {
  season: string;
  coefficients: FeatureValueSet;
}

export interface IFeature {
  name: string;
}

export interface IBookingPerMonth {
  pricingRegionCode: string;
  percentageOfNights: number;
  month: number;
}

export interface IBookingWindowPenalty {
  departMonth: number;
  leadTime: number;
  penalty: number;
}

export interface IBookingProbability {
  pricingRegionCode: string;
  year: number;
  weekNumber: number;
  bookingProbability: number;
}

export interface IWeekMapping {
  previousWeek: number;
  previousYear: number;
  currentYear: number;
  currentWeek: number;
  bookingType: string;
  calendarName: string;
  season: string;
}

export interface IUUIDName {
  uuid: string;
  name: string;
}

export interface ISeason extends IUUIDName {
  color: string;
}

export interface IOwnerWeeks {
  seasonName: string;
  numberOfWeeks: number;
}

export interface IPriceCoefficient {
  season: string;
  pricingRegionCode: string;
  featureName: string;
  coefficient: number;
}

export interface IRealizedPrice {
  weekNumber: number;
  year: number;
  price: number;
  midweekRatio: number;
  weekendRatio: number;
}

export interface IPricingRegion {
  code: string;
  discount: number;
  lengthOfStay: number;
  name: string;
}

export interface IShortBreakArrivalDistribution {
  uuid?: string;
  pricingRegionCode: string;
  bedrooms: number;
  factor: number;
  type: IShortBreakType;
}

export interface IShortBreakType {
  uuid?: string;
  nights: number;
  type: 'midweek' | 'weekend' | 'fullweek';
}

export interface ICalendarShortBreakPriceDistribution {
  uuid: string;
  type: IShortBreakType;
  value: number;
}

export interface ICalendar extends IUUIDName {
  distribution: ICalendarShortBreakPriceDistribution[];
  elasticity: number;
}

export type IPropertyType = IUUIDName;

export interface ICountry extends IUUIDName {
  code: string;
  taxRate: number;
  currency: string;
  defaultPriceType: 'NET' | 'GROSS';
  twoDigitCode: string;
}

export interface ISubRegion extends IUUIDName {
  countryRegion: string;
  pricingRegion: string;
  feature: string;
}

export interface ICountryRegion extends IUUIDName {
  country: string;
  subRegions: ISubRegion[];
}

export interface IMarkup {
  country: string;
  value: number;
}

export interface ICascadeType extends IUUIDName {
  calendar: string;
}
export interface ICascadeData {
  uuid: string;
  value: number;
  cascadeType: string;
  season: string;
}

export interface ICurrency extends IUUIDName {
  code: string;
  isDefaultForSite: boolean;
  conversionRateToDefault: number;
  prefix: string;
  suffix: string;
}

export default new AppDatabase();
