import { all, put, select, take } from 'redux-saga/effects';
import { sagas as configurationSagas } from 'stateManagement/Configuration';
import { downloadNewProperties, sagas as downstreamSagas } from 'stateManagement/Downstream';
import { sagas as engineSagas } from 'stateManagement/Engine';
import { getIsLoggedIn, sagas as loginSagas } from 'stateManagement/Login';
import { sagas as quoteSagas } from 'stateManagement/Quote';
import { sagas as upstreamDataSagas } from 'stateManagement/Upstream';

import { REHYDRATE } from 'redux-persist';
import { upstreamData } from './Upstream/actions';

function* applicationStartSaga(): any {
  yield take(REHYDRATE);
  const loggedIn = yield select(getIsLoggedIn);
  if (loggedIn) {
    yield put(upstreamData.request({}));
    yield put(downloadNewProperties.request({}));
  }
}

export default function* rootSaga() {
  yield all([
    loginSagas(),
    upstreamDataSagas(),
    quoteSagas(),
    engineSagas(),
    applicationStartSaga(),
    downstreamSagas(),
    configurationSagas(),
  ]);
}
