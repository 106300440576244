import { IReason } from 'constants/dropdownOptions';
import { IUpstreamProperty } from 'services/appDatabase';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPricingResult,
  IRevenueResult,
  IShortBreakResult,
  IStayTypeValue,
  IWeekStayTypeBreakdown,
} from 'services/engine/types';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export interface ISelectPropertiesInput {
  grade: number;
  bedrooms: number;
  postcode: string;
}

export interface ISelectPropertiesOutput {
  properties: IUpstreamProperty[];
}

interface IPricingAdjustment {
  floorPrice: number | null;
  pricing: IPricingResult[];
}

interface IAdjustmentAction<T> {
  reason: IReason;
  note: string;
  adjustment: T;
}

interface IShortBreakAdjustment {
  priceDistribution: IStayTypeValue[];
}

interface IShortBreakAdjustmentSave {
  priceDistribution: IStayTypeValue[];
  weeks: IWeekStayTypeBreakdown[];
}

export interface IComputeAllPayload {
  recalculateBenchmark: boolean;
}

export type IPricingAdjustmentActionPayload = IAdjustmentAction<IPricingAdjustment>;
export type IShortBreaksAdjustmentActionPayload = IAdjustmentAction<IShortBreakAdjustment>;
export type IShortBreaksAdjustmentSaveActionPayload = IAdjustmentAction<IShortBreakAdjustmentSave>;
export type IOccupancyAdjustmentActionPayload = IAdjustmentAction<IOccupancyResult[]>;

export const selectProperties = createAsyncAction(
  'ENGINE/SELECT_PROPERTIES_REQUEST',
  'ENGINE/SELECT_PROPERTIES_SUCCESS',
  'ENGINE/SELECT_PROPERTIES_FAILURE',
)<
  ISelectPropertiesInput,
  ISelectPropertiesOutput,
  {
    errorMessage: string;
  }
>();
export const selectPropertiesStart = createStandardAction('ENGINE/SELECT_PROPERTIES_START')();

export const computeAll = createStandardAction('ENGINE/COMPUTE/ALL')<IComputeAllPayload>();

export const adjustPricing = createStandardAction(
  'ENGINE/ADJUSTMENT/PRICING',
)<IPricingAdjustmentActionPayload>();

export const adjustShortBreaks = createStandardAction(
  'ENGINE/ADJUSTMENT/SHORTBREAKS',
)<IShortBreaksAdjustmentActionPayload>();

export const storeShortBreakAdjustment = createStandardAction(
  'ENGINE/ADJUSTMENT/SHORTBREAKS_SAVE',
)<IShortBreaksAdjustmentActionPayload>();

export const adjustOccupancy = createStandardAction(
  'ENGINE/ADJUSTMENT/OCCUPANCY',
)<IOccupancyAdjustmentActionPayload>();

export const storePricingResult =
  createStandardAction('ENGINE/COMPUTE/PRICING')<IPricingResult[]>();
export const storeShortBreakResult = createStandardAction(
  'ENGINE/COMPUTE/SHORTBREAK',
)<IShortBreakResult>();
export const storeAPCResult =
  createStandardAction('ENGINE/COMPUTE/APC')<IAveragePriceChangeResult[]>();
export const storeBaseOccupancy = createStandardAction('ENGINE/COMPUTE/BASE_OCCUPANCY')<
  IOccupancyResult[]
>();
export const storeFinalOccupancy = createStandardAction('ENGINE/COMPUTE/FINAL_OCCUPANCY')<
  IOccupancyResult[]
>();
export const storeRevenueResult = createStandardAction('ENGINE/COMPUTE/REVENUE')<IRevenueResult>();

export default {
  selectProperties,
  selectPropertiesStart,
  storeAPCResult,
  storeBaseOccupancy,
  storeFinalOccupancy,
  storePricingResult,
  storeRevenueResult,
  storeShortBreakResult,
  computeAll,
  adjustPricing,
  adjustShortBreaks,
  storeShortBreakAdjustment,
  adjustOccupancy,
};
