import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getDownstreamUpdatesInFlight } from 'stateManagement/Downstream/selectors';
import { RootState } from 'stateManagement/types';
import { colorPalette, getSpacing } from 'stylesheet';

interface IProps {
  syncing: number;
}

const Alert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blueLight};
  color: ${colorPalette.white};
  padding: ${getSpacing(2)} ${getSpacing(4)};
  border-radius: 10px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
`;

const UpdatesInFlightModal = ({ syncing }: IProps) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={!!syncing}>
      <Alert data-test="cy-sync-modal">
        <CircularProgress color="secondary" />
      </Alert>
    </Snackbar>
  );
};
export default connect((state: RootState) => ({
  syncing: getDownstreamUpdatesInFlight(state),
}))(UpdatesInFlightModal);
