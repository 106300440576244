import { ActionType, getType } from 'typesafe-actions';

import { USERS } from 'constants/dropdownOptions';
import { AnyAction } from 'redux';
import { ResetQuoteAction } from 'stateManagement/Quote';
import quote from 'stateManagement/Quote/actions';
import { IUpstreamProperty } from 'services/appDatabase';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPricingResult,
  IRevenueResult,
  IShortBreakResult,
} from 'services/engine/types';
import engineActions from './actions';

export interface AdjustmentReasonObject {
  reason: string;
  note: string;
}

export interface AdjustedResultItem<T> {
  reason?: string;
  note?: string;
  adjustmentReasons?: AdjustmentReasonObject[];
  data: T;
}

export interface AdjustedResult<T> {
  engine: AdjustedResultItem<T>;
  bdm?: AdjustedResultItem<T>;
  owner?: AdjustedResultItem<T>;
  adjusted?: AdjustedResultItem<T>;
}

export type EngineState = Readonly<{
  properties: IUpstreamProperty[];
  pricingResults: AdjustedResult<IPricingResult[]>;
  shortBreakResults: AdjustedResult<IShortBreakResult>;
  occupancyResults: AdjustedResult<IOccupancyResult[]>;
  revenueResults: IRevenueResult[];
  apcResults: IAveragePriceChangeResult[];
  floorPrice: number | null;
  computingBenchmarkProperties: boolean;
}>;

const initialState: EngineState = {
  properties: [],
  computingBenchmarkProperties: false,
  occupancyResults: {
    engine: {
      reason: '',
      note: '',
      data: [],
    },
  },
  revenueResults: [],
  shortBreakResults: {
    engine: {
      reason: '',
      note: '',
      data: { arrivalDistribution: [], weeks: [], priceDistribution: [] },
    },
  },
  pricingResults: {
    engine: {
      reason: '',
      note: '',
      data: [],
      adjustmentReasons: [],
    },
  },
  apcResults: [],
  floorPrice: null,
};

export type EngineSelectPropertiesAction = ActionType<
  | typeof engineActions.selectProperties.success
  | typeof engineActions.selectProperties.failure
  | typeof engineActions.selectPropertiesStart
>;

export type EngineComputeAllAction = ActionType<typeof engineActions.computeAll>;
export type EngineStoreAPCResultAction = ActionType<typeof engineActions.storeAPCResult>;
export type EngineStoreBaseOccupancyAction = ActionType<typeof engineActions.storeBaseOccupancy>;
export type EngineStoreFinalOccupancyAction = ActionType<typeof engineActions.storeFinalOccupancy>;
export type EngineStorePricingResultAction = ActionType<typeof engineActions.storePricingResult>;
export type EngineStoreRevenueResultAction = ActionType<typeof engineActions.storeRevenueResult>;
export type EngineStoreShortBreakResultAction = ActionType<
  typeof engineActions.storeShortBreakResult
>;
export type EnginePricingAdjustmentAction = ActionType<typeof engineActions.adjustPricing>;
export type EngineShortBreakAdjustmentStoreAction = ActionType<
  typeof engineActions.storeShortBreakAdjustment
>;
export type EngineOccupancyAdjustmentAction = ActionType<typeof engineActions.adjustOccupancy>;
export type LoadQuoteEngineAction = ActionType<typeof quote.loadQuoteEngine>;
const reducer = (state: typeof initialState = initialState, action: AnyAction): EngineState => {
  const typedAction = action as
    | EngineSelectPropertiesAction
    | EngineStoreAPCResultAction
    | EngineStoreBaseOccupancyAction
    | EngineStoreFinalOccupancyAction
    | EngineStorePricingResultAction
    | EngineStoreRevenueResultAction
    | EngineStoreShortBreakResultAction
    | EnginePricingAdjustmentAction
    | EngineShortBreakAdjustmentStoreAction
    | EngineOccupancyAdjustmentAction
    | ResetQuoteAction
    | LoadQuoteEngineAction;

  switch (typedAction.type) {
    case getType(engineActions.selectPropertiesStart):
      return {
        ...state,
        computingBenchmarkProperties: true,
      };
    case getType(engineActions.selectProperties.success):
      return {
        ...state,
        ...action.payload,
        computingBenchmarkProperties: false,
      };
    case getType(engineActions.storePricingResult):
      return {
        ...state,
        pricingResults: {
          engine: {
            reason: 'INIT',
            note: '',
            data: action.payload,
            adjustmentReasons: [],
          },
        },
        floorPrice: null,
      };
    case getType(engineActions.storeShortBreakResult):
      return {
        ...state,
        shortBreakResults: {
          engine: {
            reason: 'INIT',
            note: '',
            data: action.payload,
          },
        },
      };
    case getType(engineActions.storeAPCResult):
      return {
        ...state,
        apcResults: action.payload,
      };
    case getType(engineActions.storeBaseOccupancy):
      return {
        ...state,
        occupancyResults: {
          engine: {
            reason: 'INIT',
            note: '',
            data: action.payload,
          },
        },
      };
    case getType(engineActions.storeFinalOccupancy):
      return {
        ...state,
        occupancyResults: {
          ...state.occupancyResults,
          owner: {
            reason: 'INIT',
            note: '',
            data: action.payload,
          },
        },
      };
    case getType(engineActions.storeRevenueResult):
      return {
        ...state,
        revenueResults: action.payload,
      };

    case getType(engineActions.adjustPricing):
      // eslint-disable-next-lineno-console
      console.log('Pricing Adjustment: ', action.payload);

      let currentAdjustmentReasons: AdjustmentReasonObject[] = [
        {
          reason: action.payload.reason.code,
          note: action.payload.note || '',
        },
      ];
      if (
        state.pricingResults.adjusted &&
        state.pricingResults.adjusted.adjustmentReasons &&
        state.pricingResults.adjusted.adjustmentReasons.length
      ) {
        currentAdjustmentReasons = [
          ...state.pricingResults.adjusted.adjustmentReasons,
          {
            reason: action.payload.reason.code,
            note: action.payload.note || '',
          },
        ];
      }

      const pricingAdjustment: AdjustedResultItem<IPricingResult[]> = {
        reason: action.payload.reason.code,
        note: action.payload.note,
        data: action.payload.adjustment.pricing,
        adjustmentReasons: currentAdjustmentReasons,
      };

      const resetState = { ...state };
      // reset bdm occupancy adjustment after owner adjustment
      if (action.payload.reason.adjustedBy === USERS.OWNER) {
        resetState.occupancyResults = {
          engine: state.occupancyResults.engine,
        };
      }

      return {
        ...resetState,
        pricingResults: {
          ...state.pricingResults,
          adjusted: pricingAdjustment,
        },
        floorPrice: action.payload.adjustment.floorPrice,
      };

    case getType(engineActions.adjustOccupancy):
      return {
        ...state,
        occupancyResults: {
          ...state.occupancyResults,
          bdm: {
            reason: action.payload.reason.code,
            note: action.payload.note,
            data: action.payload.adjustment,
          },
          owner: {
            reason: action.payload.reason.code,
            note: action.payload.note,
            data: action.payload.adjustment,
          },
        },
      };

    case getType(engineActions.storeShortBreakAdjustment):
      // eslint-disable-next-lineno-console
      console.log('ShortBreak Adjustment: ', action.payload);

      return {
        ...state,
        // reset bdm occupancy adjustment after owner adjustment
        occupancyResults: {
          engine: state.occupancyResults.engine,
        },
        shortBreakResults: {
          ...state.shortBreakResults,
          owner: {
            reason: action.payload.reason.code,
            note: action.payload.note,
            data: {
              arrivalDistribution: state.shortBreakResults.engine.data.arrivalDistribution,
              weeks: action.payload.adjustment.weeks,
              priceDistribution: action.payload.adjustment.priceDistribution,
            },
          },
        },
      };
    case getType(quote.reset):
      return initialState;
    case getType(quote.loadQuoteEngine):
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
