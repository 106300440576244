import { push } from 'connected-react-router';
import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import ROUTES from 'constants/routes';
import { call, put, takeEvery } from 'redux-saga/effects';
import { downloadNewProperties } from 'stateManagement/Downstream';
import { upstreamData } from 'stateManagement/Upstream';
import appDB from 'services/appDatabase';
import client from 'services/networking/request';
import { ActionType, getType } from 'typesafe-actions';
import { loginUser, logout } from './actions';

export function* loginUserSaga(action: ActionType<typeof loginUser.request>) {
  const endpoint = `/auth/jwt/create`;
  try {
    const token: string | undefined = yield call([client, client.login], endpoint, action.payload);
    if (token) {
      yield put(loginUser.success({ token }));
      yield put(push(ROUTES.NEW_QUOTE));
      yield put(upstreamData.request({}));
      yield put(downloadNewProperties.request({}));
    } else {
      yield put(loginUser.failure({ errorMessage: 'No token in login response body' }));
    }
  } catch (error) {
    yield put(
      loginUser.failure({
        errorMessage:
          'Not a recognised username/password combination, please review and if problem persists contact ITServiceDeskAwaze@awaze.co.uk',
      }),
    );
  }
}

export function* logoutUserSaga(action: ActionType<typeof logout.request>): any {
  const unsavedPropertiesCollection = appDB.newProperties
    .where('status')
    .equals(QuoteStatus.POPULATING)
    .or('queueStatus')
    .anyOf(QuoteQueueStatus.FAILED, QuoteQueueStatus.QUEUED, QuoteQueueStatus.UPLOADING);

  // @ts-ignore-next-line
  const unsavedProperties = yield call([
    unsavedPropertiesCollection,
    unsavedPropertiesCollection.count,
  ]);

  if (unsavedProperties > 0) {
    const continueLogout = yield call(
      [window, window.confirm],
      `There are ${unsavedProperties} unsaved quotes. Logging out now will lose the changes you've made to those properties. Continue logging out?`,
    );

    if (!continueLogout) {
      yield put(logout.failure());
      return;
    }
  }

  yield call([localStorage, localStorage.clear]);
  yield call([appDB, appDB.clearDBExcept], []);

  yield put(logout.success());
}

export default function* loginSagas() {
  yield takeEvery(getType(loginUser.request), loginUserSaga);
  yield takeEvery(getType(logout.request), logoutUserSaga);
}
