import { QuoteStatus } from 'constants/quotes';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LoadQuoteAction } from 'stateManagement/Quote';
import quote from 'stateManagement/Quote/actions';
import { getNewProperty } from 'stateManagement/Quote/selectors';
import appDB, { INewProperty, INewPropertyQuote, IUpstreamProperty } from 'services/appDatabase';
import engine from 'services/engine';
import { PricingEngineException } from 'services/engine/exceptions';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPricingResult,
  IShortBreakResult,
} from 'services/engine/types';
import { endEngineLogs, startEngineLogs } from 'services/logging';
import { getType } from 'typesafe-actions';
import {
  adjustOccupancy,
  adjustPricing,
  adjustShortBreaks,
  computeAll,
  selectProperties,
  selectPropertiesStart,
  storeAPCResult,
  storeBaseOccupancy,
  storeFinalOccupancy,
  storePricingResult,
  storeRevenueResult,
  storeShortBreakAdjustment,
  storeShortBreakResult,
} from './actions';
import {
  AdjustedResultItem,
  EngineComputeAllAction,
  EngineShortBreakAdjustmentStoreAction,
} from './reducer';
import {
  getAdjustedPricing,
  getAveragePriceChange,
  getBDMOccupancy,
  getLatestOccupancy,
  getLatestPricingWithFloorPrice,
  getLatestShortBreak,
  getProperties,
} from './selectors';

function* engineSelectPropertiesSaga() {
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    yield put(selectPropertiesStart());
    // eslint-disable-next-line
    console.time('PROPERTIES');
    const benchmarkProperties: IUpstreamProperty[] = yield call(
      [engine, engine.selectBenchmarkProperties],
      newProperty,
    );
    // eslint-disable-next-line
    console.timeEnd('PROPERTIES');
    // eslint-disable-next-line
    console.log('Similar properties:', benchmarkProperties);

    if (benchmarkProperties && benchmarkProperties.length > 0) {
      yield put(selectProperties.success({ properties: benchmarkProperties }));
    } else {
      yield put(
        selectProperties.failure({
          errorMessage: `No properties found with bedrooms: ${newProperty.bedrooms}
          and grade: ${newProperty.grade}.`,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
}

function* loadQuoteSaga(action: LoadQuoteAction) {
  try {
    const newProperty: INewPropertyQuote = action.payload;
    // eslint-disable-next-line
    console.time('PROPERTIES');

    const benchmarkProperties: IUpstreamProperty[] = yield appDB.upstreamProperties
      .where('uuid')
      .anyOf(newProperty.benchmarkProperties)
      .toArray();

    // eslint-disable-next-line
    console.timeEnd('PROPERTIES');

    if (benchmarkProperties && benchmarkProperties.length > 0) {
      yield put(
        quote.loadQuoteEngine({
          properties: benchmarkProperties,
          pricingResults: newProperty.pricingResults,
          shortBreakResults: newProperty.shortBreakResults,
          occupancyResults: newProperty.occupancies,
          revenueResults: newProperty.revenues,
          apcResults: newProperty.apcResults,
          floorPrice: newProperty.floorPrice,
          computingBenchmarkProperties: false,
        }),
      );
    } else {
      yield put(
        selectProperties.failure({
          errorMessage: `No properties found with bedrooms: ${action.payload.bedrooms}
          and grade: ${action.payload.grade}.`,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
}

function* computeAllSaga(action: EngineComputeAllAction) {
  startEngineLogs('ENGINE', false);
  try {
    if (action.payload.recalculateBenchmark) {
      yield call(engineSelectPropertiesSaga);
    }
    yield call(computeBaseOccupancy);
    yield call(computePricing);
    yield put(
      quote.update({
        status: QuoteStatus.DRAFT,
      }),
    );
    endEngineLogs('ENGINE');
  } catch (error) {
    endEngineLogs('ENGINE');
    console.warn(error);
  }
}

function* computePricing() {
  startEngineLogs('PRICING');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const result: IPricingResult[] = yield call(
      [engine, engine.computePricing],
      newProperty,
      benchmarkProperties,
    );
    endEngineLogs('PRICING');

    // Save new engine pricing
    yield put(storePricingResult(result));

    // Move on to short break calculation
    yield call(computeShortbreak);
  } catch (error) {
    endEngineLogs('PRICING');
    console.warn(error);
  }
}

function* computeShortbreak() {
  startEngineLogs('SHORTBREAK');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const pricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricingWithFloorPrice,
    );
    if (!pricingResults) {
      throw new PricingEngineException('No pricing result for shortbreak computation');
    }

    const result: IShortBreakResult = yield call(
      [engine, engine.computeShortBreak],
      newProperty,
      benchmarkProperties,
      pricingResults.data,
    );
    endEngineLogs('SHORTBREAK');
    // Save new engine short break
    yield put(storeShortBreakResult(result));

    // Move on to average price change calculation
    yield call(computeAPC);
  } catch (error) {
    endEngineLogs('SHORTBREAK');
    console.warn(error);
  }
}

function* onShortbreakAdjustment(action: EngineShortBreakAdjustmentStoreAction) {
  startEngineLogs('PRICE ADJUSTED SHORTBREAK');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const pricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricingWithFloorPrice,
    );
    if (!pricingResults) {
      throw new PricingEngineException('No pricing result for shortbreak computation');
    }

    const result: IShortBreakResult = yield call(
      [engine, engine.computeShortBreak],
      newProperty,
      benchmarkProperties,
      pricingResults.data,
      action.payload.adjustment.priceDistribution,
    );
    endEngineLogs('PRICE ADJUSTED SHORTBREAK');
    // Save new engine short break
    yield put(
      storeShortBreakAdjustment({
        reason: action.payload.reason,
        note: action.payload.note,
        adjustment: result,
      }),
    );

    // Move on to average price change calculation
    yield call(computeAPC);
  } catch (error) {
    endEngineLogs('PRICE ADJUSTED SHORTBREAK');
    console.warn(error);
  }
}

function* computeAPC() {
  startEngineLogs('AVERAGE PRICE CHANGE');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const { data: ownerShortBreak }: AdjustedResultItem<IShortBreakResult> = yield select(
      getLatestShortBreak,
    );
    const { data: adjustedPricing }: AdjustedResultItem<IPricingResult[]> = yield select(
      getAdjustedPricing,
    );
    const result: IAveragePriceChangeResult[] = yield call(
      [engine, engine.computeAPC],
      newProperty,
      benchmarkProperties,
      ownerShortBreak,
      adjustedPricing,
    );
    endEngineLogs('AVERAGE PRICE CHANGE');

    // Save new engine APC
    yield put(storeAPCResult(result));

    // Move on to final occupancy calculation
    yield call(computeFinalOccupancy);
  } catch (error) {
    endEngineLogs('AVERAGE PRICE CHANGE');
    console.warn(error);
  }
}

function* computeBaseOccupancy() {
  startEngineLogs('OCCUPANCY');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    // eslint-disable-next-line
    console.log('Computing pricing for property', newProperty.uuid);
    const benchmarkProperties: IUpstreamProperty[] = yield select(getProperties);
    const result: IOccupancyResult[] = yield call(
      [engine, engine.computeOccupancy],
      newProperty,
      benchmarkProperties,
    );
    endEngineLogs('OCCUPANCY');

    // Save new engine base occupancy
    yield put(storeBaseOccupancy(result));
  } catch (error) {
    endEngineLogs('OCCUPANCY');
    console.warn(error);
  }
}

function* computeFinalOccupancy() {
  startEngineLogs('PRICE ADJUSTED OCCUPANCY');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const previousOccupancyResults: AdjustedResultItem<IOccupancyResult[]> = yield select(
      getBDMOccupancy,
    );
    const averagePriceChangeResults: IAveragePriceChangeResult[] = yield select(
      getAveragePriceChange,
    );
    const result: IOccupancyResult[] = yield call(
      [engine, engine.computePriceAdjustedOccupancy],
      newProperty,
      previousOccupancyResults.data,
      averagePriceChangeResults,
    );
    endEngineLogs('PRICE ADJUSTED OCCUPANCY');

    // Save new engine final occupancy
    yield put(storeFinalOccupancy(result));

    // Move on to revenue
    yield call(computeRevenue);
  } catch (error) {
    endEngineLogs('PRICE ADJUSTED OCCUPANCY');
    console.warn(error);
  }
}

function* computeRevenue(): any {
  startEngineLogs('REVENUE');
  try {
    const newProperty: INewProperty = yield select(getNewProperty);
    const occupancyResults: AdjustedResultItem<IOccupancyResult[]> | null = yield select(
      getLatestOccupancy,
    );
    const pricingResults: AdjustedResultItem<IPricingResult[]> | null = yield select(
      getLatestPricingWithFloorPrice,
    );
    if (!pricingResults) {
      throw new PricingEngineException('No pricing result for revenue computation');
    }
    if (!occupancyResults) {
      throw new PricingEngineException('No occupancy result for revenue computation');
    }
    const result = yield call(
      [engine, engine.computeRevenue],
      pricingResults.data,
      occupancyResults.data,
      newProperty,
    );
    endEngineLogs('REVENUE');

    // Save new engine final occupancy
    yield put(storeRevenueResult(result));

    // set draft status if this is the first time we calculate revenue
    if (newProperty.status === QuoteStatus.POPULATING) {
      yield put(quote.update({ status: QuoteStatus.DRAFT }));
    }
  } catch (error) {
    endEngineLogs('REVENUE');
    console.warn(error);
  }
}

export default function* engineSagas() {
  yield takeLatest(
    [getType(selectProperties.request), getType(quote.create)],
    engineSelectPropertiesSaga,
  );
  yield takeLatest(getType(computeAll), computeAllSaga);
  yield takeLatest(getType(quote.loadQuote), loadQuoteSaga);
  yield takeLatest(getType(adjustPricing), computeShortbreak);
  yield takeLatest(getType(adjustShortBreaks), onShortbreakAdjustment);
  yield takeLatest(getType(adjustOccupancy), computeFinalOccupancy);
}
