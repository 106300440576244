import _zip from 'lodash/zip';
import { IBookingProbability, INewProperty, IPricingRegion } from 'services/appDatabase';
import BaseEngine from './base';
import { PricingEngineException } from './exceptions';
import { IWeekPricingData } from './pricing';
import { IOccupancyResult, IPricingResult, IRevenueEngine, IRevenueResult } from './types';

interface IWeekRevenue {
  weekNumber: number;
  open: boolean;
  price: number;
  bookingProbability: number;
}

interface IAveragePricesForRevenue {
  year: number;
  averageMidlinePrice: number;
  averageRealizedPrice: number;
}

class RevenueEngine extends BaseEngine implements IRevenueEngine {
  public async computeRevenue(
    pricingResults: IPricingResult[],
    occupancyResult: IOccupancyResult[],
    newProperty: INewProperty,
  ): Promise<IRevenueResult[]> {
    // eslint-disable-next-lineno-console
    console.log('######################### REVENUE ##############################');
    const pricingRegionCode = await this.getPricingRegionCode(newProperty);
    const bookingProbabilities = await this.selectBookingProbabilities(pricingRegionCode);
    const pricingRegion = await this.selectPricingRegion(pricingRegionCode);

    const averagePrices: IAveragePricesForRevenue[] = pricingResults.map((pricingResult) => {
      const yearData = this.getYearDataWithBookingProbability(
        pricingResult.yearData,
        bookingProbabilities,
        pricingRegionCode,
      );
      const averageMidlinePrice = this.getAverageMidline(yearData);

      return {
        year: pricingResult.year,
        averageMidlinePrice,
        averageRealizedPrice: averageMidlinePrice * (1 + pricingRegion.discount),
      };
    });

    const revenueResults = _zip(occupancyResult, averagePrices).map(([occupancy, revenue]) => {
      if (!occupancy || !revenue) {
        throw new PricingEngineException('Inconsistent occupancy and revenue array sizes.');
      }
      return {
        year: occupancy.year,
        lowest: (occupancy.nights.lowest / 7) * revenue.averageRealizedPrice,
        highest: (occupancy.nights.highest / 7) * revenue.averageRealizedPrice,
      };
    });

    // eslint-disable
    console.log('averagePrices', averagePrices);

    console.group('%cRevenue result', 'color: #2EA68D; text-decoration: underline;');
    console.log(revenueResults);
    console.groupEnd();
    // eslint enable
    return revenueResults;
  }

  private getYearDataWithBookingProbability(
    yearData: IWeekPricingData[],
    bookingProbabilities: IBookingProbability[],
    pricingRegionCode: string,
  ) {
    return yearData.map((weekEntry) => {
      const bookingProbability = bookingProbabilities.find(
        (bp) => bp.weekNumber === weekEntry.weekNumber,
      );
      if (!bookingProbability) {
        throw new PricingEngineException(
          `Cannot find a booking probability for pricingRegionCode: ${pricingRegionCode} and weekNumber: ${weekEntry.weekNumber}`,
        );
      }
      return {
        ...weekEntry,
        bookingProbability: bookingProbability.bookingProbability,
      };
    });
  }

  private async selectPricingRegion(pricingRegionCode: string): Promise<IPricingRegion> {
    const pricingRegion = await this.db.pricingRegion
      .where('code')
      .equals(pricingRegionCode)
      .first();
    if (!pricingRegion) {
      throw new PricingEngineException(
        `No region data found in database for region code: ${pricingRegionCode}`,
      );
    }
    return pricingRegion;
  }

  private getAverageMidline(yearData: IWeekRevenue[]) {
    const sumOfBookingProbabilities = yearData.reduce(
      (acc, weekEntry) => acc + (weekEntry.open ? weekEntry.bookingProbability : 0),
      0,
    );
    const sumOfWeightedPrices = yearData.reduce(
      (acc, weekEntry) =>
        acc + (weekEntry.open ? weekEntry.bookingProbability * weekEntry.price : 0),
      0,
    );

    if (sumOfBookingProbabilities === 0) {
      throw new Error('The sum of booking probabilites is null for year');
    }

    return sumOfWeightedPrices / sumOfBookingProbabilities;
  }
}

export default RevenueEngine;
