import { connect } from 'react-redux';
import { getUpdateAvailable } from 'stateManagement/Configuration/selectors';
import { RootState } from 'stateManagement/types';
import { UpdateRequiredModal } from './update';

const mapStateToProps = (state: RootState) => ({
  open: getUpdateAvailable(state),
});

export default connect(mapStateToProps)(UpdateRequiredModal);
