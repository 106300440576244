import { ICurrency } from 'services/appDatabase';
import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { setEngineCurrency, setUserCurrency } from './actions';

export type CurrencyState = Readonly<{
  userCurrency: ICurrency | null;
  engineCurrency: ICurrency | null;
}>;

type SetEngineCurrencyAction = ActionType<typeof setEngineCurrency>;
type SetUserCurrencyAction = ActionType<typeof setUserCurrency>;

export type CurrencyAction = SetEngineCurrencyAction | SetUserCurrencyAction;

const initialState: CurrencyState = {
  userCurrency: null,
  engineCurrency: null,
};

const reducer = (state: typeof initialState = initialState, action: AnyAction) => {
  const typedAction = action as CurrencyAction;
  switch (typedAction.type) {
    case getType(setUserCurrency):
      return {
        ...state,
        userCurrency: typedAction.payload,
      };
    case getType(setEngineCurrency):
      return {
        ...state,
        engineCurrency: typedAction.payload,
      };
    default:
      return state;
  }
};

export default reducer;
