import { createAsyncAction } from 'typesafe-actions';

export const updateAvailable = createAsyncAction(
  'FORCE_UPDATE/REQUEST',
  'FORCE_UPDATE/SUCCESS',
  'FORCE_UPDATE/FAILURE',
)<undefined, undefined, undefined>();

export default {
  updateAvailable,
};
