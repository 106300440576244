import * as Sentry from '@sentry/browser';
import { call, delay, put } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import { updateAvailable } from 'stateManagement/Configuration/actions';
import { getDownstreamUpdatesInFlight } from 'stateManagement/Downstream/selectors';
import client from 'services/networking/request';

export function* versionCheckSaga() {
  while (true) {
    const inFlightUpdates: number = yield select(getDownstreamUpdatesInFlight);
    if (inFlightUpdates === 0) {
      const endpoint = `/health`;
      if (navigator.onLine) {
        try {
          const response: { version: string } = yield call([client, client.get], endpoint);
          if (window.config.sentry.version !== response.version && response.version !== 'unknown') {
            if (navigator && navigator.serviceWorker) {
              unregisterSWs();
            }

            yield put(updateAvailable.success());
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
    yield delay(60 * 1000);
  }
}

const unregisterSWs = async () => {
  const regs = await window.navigator.serviceWorker.getRegistrations();
  regs.forEach((registration) => registration.unregister());
  if ('caches' in window) {
    const cacheKeys = await window.caches.keys();
    cacheKeys.forEach((cacheKey) => window.caches.delete(cacheKey));
  }
};

export default function* configurationSagas() {
  yield versionCheckSaga();
}
